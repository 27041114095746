/* ---------------------------------------------------------------------------------------------------------------------
 * Sample data for categories (mostly delegated to functional/Categories
 *
 * Note: the ids for categories are nested:
 *  categories: {
 *    vanderbilt: {
 *      unassigned: Id1,
 *      airConditioning: Id2,
 *      ...}
 *    ridgedale: {...},
 *
 * As opposed to the "flat" ids in sample-ids.js
 *
 *--------------------------------------------------------------------------------------------------------------------- */

import { Categories, mapObject, mergeRight, pluck, zipObject } from '@range.io/functional'

const jeff = '3e4637e1-02f4-4fe3-b714-c281fb1ea691'

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

// Add the metadata fields to a category
const enhanceCategory = category =>
    mergeRight(category, {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: jeff,
        lastUpdatedBy: jeff,
    })

/*
 * From an object where entry is an Id and each value a Category, invert it so the Category's name is the key and
 * the id is the value
 * @sig extractCategoryIds :: { Id: { ..., name: Name, ... }} -> { Name: Id }
 */
const extractCategoryIds = categories => {
    const ids = Object.keys(categories)
    const names = pluck('name', Object.values(categories))
    return zipObject(names, ids)
}

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

// Return a copy of defaultCategories where each id begins with prefix
// @sig categorySet :: String -> { Id: Category }
const categorySet = prefix => {
    const categories = Categories.defaultCategories(prefix)
    return mapObject(enhanceCategory, categories)
}

// sample caetegory data
const sampleCategories = {
    vanderbilt: categorySet('8f5068c9-14ae-491f-a3c6-'),
    ridgedale: categorySet('9be99c31-9050-494c-ae67-'),
    thePreserve: categorySet('75a4a0b1-7fb9-4641-8551-'),
}

// map each category name for a project to its id
const sampleCategoryIds = {
    vanderbilt: extractCategoryIds(sampleCategories.vanderbilt),
    ridgedale: extractCategoryIds(sampleCategories.ridgedale),
    thePreserve: extractCategoryIds(sampleCategories.thePreserve),
}

export { sampleCategoryIds, sampleCategories }
