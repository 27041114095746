/*
 * DataStore
 *
 * Manage the mapping between Firestore paths and our raw-sample-data data.
 *
 * The hierarchies are nominally the same, but to simulate the Firestore engine we also have to:
 *
 * - REMOVE the subcollections from the "documents" that we return as Firestore objects
 *   (to prevent a "project" from having a field for collaborations, when it should instead have a subcollection)
 * - Map timestamp integers to MockTimestamps
 * - Add and remove listeners so that we can simulate the Firestore onSnapshot callback experience
 */
import { assocPath, filterObject, mapObject, mergeRight, omit } from '@range.io/functional'
import { v4 } from 'uuid'
import MockCollectionReference from './firestore/mock-collection-reference.js'
import MockDocumentReference from './firestore/mock-document-reference.js'
import MockQuery from './firestore/mock-query.js'
import MockTimestamp from './mock-timestamp.js'

const DELETE_FIELD_MARKER = '<delete-field>'

const subcollectionNames = [
    'canvasSources',
    'canvases',
    'collaborations',
    'comments',
    'features',
    'geometries',
    'invitations',
    'participants',
    'presences',
    'statusNames',
    'suspendedParticipants',
    'tagNames',
    'updates',
    'uploads',
]

/*
 * Convert a path to an array of strings:
 *    "/users/3e4637e1-02f4-4fe3-b714-c281fb1ea691/invitations" ->
 *    ["users", "3e4637e1-02f4-4fe3-b714-c281fb1ea691", "invitations"]
 */
const pathToParts = path => {
    if (path.at(-1) === '/') path = path.slice(0, -1)
    if (path[0] === '/') path = path.slice(1)
    return path.split('/')
}

/*
 * We will send a callback to the given subscription if the path in the actualDocRef "matches" that of the subscription
 *
 * The ACTUAL docRef MUST be a MockDocumentReference, because we've always modified a specific document.
 * On the other hand, we could be SUBSCRIBED to either a specific document or a query (that is: a document collection).
 *
 * If the SUBSCRIPTION is for a:
 *
 * - specific document  the ACTUAL doc's path must exactly match the subscription's path
 * - collection         the ACTUAL doc's path must start with subscription's path, and include only 1 more '/' character
 *
 */
const UUID = '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}'
const subscriptionMatchesReference = actualDocRef => subscription => {
    if (!MockDocumentReference.is(actualDocRef)) throw new Error(`${actualDocRef} should be a MockDocumentReference`)

    const actualPath = actualDocRef.path
    const subscriptionRef = MockDocumentReference.is(subscription.ref) ? subscription.ref : subscription.ref.reference
    const subscriptionPath = subscriptionRef.path

    return MockCollectionReference.is(subscriptionRef)
        ? actualPath.match(new RegExp(`^${subscriptionPath}${UUID}$`, 'i')) // collection: match path/<uuid>
        : actualPath === subscriptionPath // document: use exact match
}

/* ---------------------------------------------------------------------------------------------------------------------
 * DataStore
 *
 * Map OUR (sample) JSON data to "Firestore data" (for instance, references and snapshots)
 * ------------------------------------------------------------------------------------------------------------------ */
const DataStore = data => {
    /*
     * Return an object at the given path in our raw-sample-data
     */
    const ourPath = (path, o) => {
        const fields = typeof path === 'string' ? path.split('.') : path

        if (path.length === 0) return o // special case; just return the whole thing

        let result = o
        let descendents = fields

        while (descendents.length) {
            // Firestore allows references to data that doesn't exist (eg. suspendedParticipants that *might* be there)
            if (!result?.[descendents?.[0]]) return []

            result = result[descendents[0]]
            descendents = descendents.slice(1)
        }

        return result
    }

    // convert milliseconds to Firestore timestamps so our data looks like Firestore data when we read it in
    // should cover: timestamp, dueDateTimestamp, completedTimestamp, archivedTimestamp, lastPresenceTimestamp, etc.
    const recursivelyConvertMillisToTimestamps = o => {
        if (Array.isArray(o) || typeof o !== 'object') return o

        for (const key in o) {
            if (key.toLowerCase().includes('timestamp')) {
                o[key] = MockTimestamp.fromMillis(o[key])
            }
        }

        mapObject(recursivelyConvertMillisToTimestamps, o)
        return o
    }

    // Firestore doesn't include subcollections when sending a Document,
    // so we remove them before returning a "document" from our data tree
    const removeSubcollections = item => omit(subcollectionNames, item)

    /*
     * Reference -> { id: Id, item: {k:v} }
     *
     * Because our hierarchy includes the subcollection data -- but the Firestore document doesn't include
     * the subcollections directly -- we have to REMOVE the subcollection data before returning the object
     */
    const documentAtReference = reference => {
        const parts = pathToParts(reference.path)
        const prefix = parts.slice(0, parts.length - 1)
        const id = parts.at(-1)
        const collection = ourPath(prefix, data)
        const item = collection[id]

        if (!item) console.log(`Couldn't find item for reference ${reference}`)

        return { id, item: removeSubcollections(item) }
    }

    /*
     * Reference -> { <id>: {k:v} }
     */
    const collectionAtReference = reference => {
        const parts = pathToParts(reference.path)
        const collection = ourPath(parts, data) ?? []

        if (!collection) console.log(`Couldn't find collection for reference ${reference}`)

        return mapObject(removeSubcollections, collection)
    }

    // remove any entries where the value is the special marker set by Firestore.deleteField()
    const deleteFieldsMarkedForDelete = o => filterObject(p => p !== DELETE_FIELD_MARKER, o)

    const sendUpdateCallbacks = reference => {
        const matchingSubscriptions = subscriptions.filter(subscriptionMatchesReference(reference))

        // simulate async
        setTimeout(() => matchingSubscriptions.forEach(subscription => subscription.callback(reference)), 0)
    }

    /*
     * Send the newReference to each subscriber listening to the newReference's parent collection:
     * a subscriber to 'organizations' should be called when 'organizations/12345' is added
     */
    const sendSetCallbacks = newReference => {
        // find all the subscribers listening to the newReference's parent collection
        const matchQuery = subscription => {
            let subscriptionPath = subscription.ref.reference.path
            let referencePath = newReference.path

            // canonicalize paths by removing '/' if it's there
            subscriptionPath = subscriptionPath[0] === '/' ? subscriptionPath.slice(1) : subscriptionPath
            referencePath = referencePath[0] === '/' ? referencePath.slice(1) : referencePath

            return referencePath.startsWith(subscriptionPath)
        }

        // Include only subscribers listening to collections
        const querySubscriptions = subscriptions.filter(s => MockQuery.is(s.ref))

        // Include only subscribers listening to newReference's parent collection
        const matchingSubscriptions = querySubscriptions.filter(matchQuery)

        // simulate async
        setTimeout(() => matchingSubscriptions.forEach(subscription => subscription.callback(newReference)), 0)
    }

    // store a value INTO our hierarchical data
    const set = (reference, o) => {
        o = deleteFieldsMarkedForDelete(o) // unlikely, but possible
        const parts = pathToParts(reference.path)
        data = assocPath(parts, o, data)

        sendSetCallbacks(reference)
    }

    // store a value INTO our hierarchical data
    const update = (reference, changes) => {
        changes = deleteFieldsMarkedForDelete(changes)

        const parts = pathToParts(reference.path)
        const oldData = ourPath(parts, data)
        const newData = mergeRight(oldData, changes)
        data = assocPath(parts, newData, data)

        sendUpdateCallbacks(reference)
    }

    // add a callback to a reference, so we can call it whenever the value at the reference changes --
    // but Firestore also calls it immediately, and so do we
    const addSubscription = (ref, callback, errorCallback) => {
        const id = v4()
        const subscription = { id, ref, callback, errorCallback }
        subscriptions = subscriptions.concat(subscription)

        setTimeout(() => callback(ref), 0)

        return id
    }

    // Remove a subscription
    const removeSubscription = id => (subscriptions = subscriptions.filter(s => s.id !== id))

    // -----------------------------------------------------------------------------------------------------------------
    // API
    // -----------------------------------------------------------------------------------------------------------------
    let subscriptions = []
    data = recursivelyConvertMillisToTimestamps(data)

    return {
        addSubscription,
        removeSubscription,
        documentAtReference,
        collectionAtReference,

        set,
        update,
    }
}

// Return the final part of a DocumentReference, which is just its id
DataStore.id = path => pathToParts(path).at(-1)

export { DELETE_FIELD_MARKER }
export default DataStore
