/*
 * A dropdown menu with tooltip that shows:
 *
 * - a list of items (exactly 1 of which is selected)
 * - a search box that filters the items in the list
 *
 * Each item must have an `id` and a `name`
 * If there's a selectedItem item, it should be one of the items
 *
 * If you want to render the item in some way other than the default, pass in a renderItem function
 *
 * Lightly generalized from CanvasSelector in Navigator.js
 */

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { styled } from '../range-theme/index.js'
import { Button, DropdownMenuWithTooltip, FlexColumn, Icon, ScrollArea, Text, TextInput } from './index.js'

const StyledItemText = styled(Text, {
    color: '$neutral04',
    fontSize: '14px',
    fontWeight: '600 !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'left',
})

const StyledItemLabel = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
})

const StyledButton = styled(Button, {
    height: 32,
    minHeight: 32,
    paddingLeft: '6px',
    fs: '12px',
    justifyContent: 'flex-start',
    br: 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

/*
 * A default renderItem function that renders a StyledButton with
 */
const defaultRenderItem = (isItemSelected, item) => (
    <StyledButton
        style={{ justifyContent: 'flex-start' }}
        variant="dropdown-menuitem-navigator"
        data-selected={isItemSelected}
    >
        <StyledItemLabel>{item.name}</StyledItemLabel>
    </StyledButton>
)

const defaultRenderTrigger = selectedItem => (
    <StyledButton>
        <StyledItemText>{selectedItem.name}</StyledItemText>
        <Icon iconSize="16px" name="chevronDown" />
    </StyledButton>
)

/*
 * Render dropdown
 *
 * If ExtraLink is present (eg. a Link to "Edit Categories"), it will be rendered at the bottom of the list
 */
const SingleSelectWithFilter = ({
    placeholder,
    tooltipText,
    items,
    selectedItem,

    setSelectedItem,
    renderItem = defaultRenderItem,
    renderTrigger = defaultRenderTrigger,
    ExtraLink, // optional
}) => {
    const resetSearch = () => {
        setFilteredItems(items)
        setInputValue('')
    }

    const handleItemSelected = item => {
        setSelectedItem(item)
        resetSearch()
    }

    const renderItemWithTooltip = item => {
        if (!item) return null

        const isItemSelected = selectedItem?.id === item.id
        return (
            <DropdownMenuWithTooltip.MenuItem
                key={item.id}
                onSelect={() => handleItemSelected(item.id)}
                onPointerLeave={event => event.preventDefault()}
                onPointerMove={event => event.preventDefault()}
            >
                {renderItem(isItemSelected, item)}
            </DropdownMenuWithTooltip.MenuItem>
        )
    }

    const boxCss = {
        mt: 5,
        width: '276px',
        boxSizing: 'border-box',
        boxShadow: '0px 6px 10px #00000010',
        marginLeft: 12,
    }

    const [inputValue, setInputValue] = useState('')
    const [filteredItems, setFilteredItems] = useState(items)

    useEffect(() => {
        // update the list of filtered items based on the new value of the input text field
        const filtered = items.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
        setFilteredItems(filtered)
    }, [inputValue])

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText={tooltipText}>
                {renderTrigger(selectedItem)}
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={boxCss} side="bottom" align="middle" sideOffset={-1} loop={true}>
                <DropdownMenuWithTooltip.MenuItem
                    css={{ padding: 0 }}
                    onSelect={e => e.preventDefault()} // prevent the dropdown from collapsing when input is clicked
                    onPointerLeave={event => event.preventDefault()}
                    onPointerMove={event => event.preventDefault()}
                >
                    <TextInput
                        placeholder={placeholder}
                        onChange={setInputValue}
                        value={inputValue}
                        onKeyDown={e => {
                            // allow bubbling only for ArrowDown, ArrowUp and Escape
                            if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Escape') e.stopPropagation()
                        }}
                    />
                </DropdownMenuWithTooltip.MenuItem>

                <ScrollArea maxHeight={'75vh'}>
                    <FlexColumn css={{ display: 'flex', fd: 'column', gap: '8px' }} orientation="vertical">
                        {filteredItems.length ? (
                            filteredItems.map(renderItemWithTooltip)
                        ) : (
                            <StyledItemLabel css={{ paddingLeft: '10px', color: '$neutral05' }}>
                                No results found
                            </StyledItemLabel>
                        )}
                    </FlexColumn>
                </ScrollArea>
                {ExtraLink && <ExtraLink />}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

const ItemShape = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

SingleSelectWithFilter.propTypes = {
    placeholder: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,

    items: PropTypes.arrayOf(PropTypes.shape(ItemShape)),
    selectedItem: PropTypes.shape(ItemShape),

    setSelectedItem: PropTypes.func.isRequired,
    renderItem: PropTypes.func,
    renderTrigger: PropTypes.func,
}

export { StyledButton, StyledItemLabel }
export default SingleSelectWithFilter
