/*
 * See Readme
 */

import { Collaboration, Feature, FeedItem } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { pluck, renameKeys, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { isUpdateAllowed } from '../../redux/redux-selectors-permissions.js'
import * as Segment from '../../segment/segment.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { updateCollaboration } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// CollaborationChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CollaborationChangedCommand = taggedSum('CollaborationChangedCommand', {
    Inbound: {
        feedItem: 'FeedItem',
    },
    Outbound: {
        id: StringTypes.Id, // Collaboration id
        changes: 'Object', // each entry defines key/value of the existing Collaboration that changed
        userId: StringTypes.OptionalId, // the User making the change
        skipUpdate: 'Boolean?', // not all Collaboration changes require an Update object (changing tags, for instance)
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CollaborationChangedCommand.Inbound has arrived from Firestore; send it to redux
 */
const runInboundCommand = async (resources, command) => {
    const collaboration = await itemFromFeedItem(Collaboration, 'Collaboration', 'updated', command.feedItem)
    if (!collaboration) return

    const { dispatch, getState } = resources

    /*
     * Usually, when we get a "collaboration changed" message, we have already received the feature and geometry
     * (which arrived together in "collaboration added" message). That means by the time we get here, we
     * can look up the related Feature and find it.
     *
     * However, when there is an 'access-granted' feedItem, it will sometimes (maybe always) be preceded by
     * a "collaboration changed" message. (In this case, we receive "collaboration changed" because, in fact,
     * the collaboration's permissions HAVE changed, and likely the followers as well.)
     *
     * So, if we can't find the related feature, we don't want to do anything here, because there is an 'access granted'
     * coming next, which will properly download all the related data. In fact, if we DO add the collaboration, our
     * Redux data will be incorrect, because we'll have a Collaboration without a related Feature, and the app crashes
     */
    const feature = ReduxSelectors.itemWithId(getState(), Feature, collaboration.featureId)
    if (feature) dispatch(ReduxActions.collaborationAdded(collaboration))
}

/*
 * A CollaborationChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const toUpdateFormat = o =>
        renameKeys(
            {
                dueDate: ['dueDateTimestamp', () => o.dueDate?.getTime() || null], // turn date to miliseconds
            },
            o
        )

    // one track event per added tag
    const sendAddedTagsToSegment = () => {
        const segmentTrackParams = ReduxSelectors.paramsForTrackEvent(getState())
        const trackOne = tagName =>
            Segment.sendTrack('tag added', tagName.id, { tagName: tagName.name, ...segmentTrackParams })
        const oldValue = collaboration.tags
        const newValue = collaborationChanges.tags

        const added = newValue.filter(item => !oldValue.includes(item)) // report only ADDING tags
        added.map(trackOne)
    }

    const { id, changes: collaborationChanges } = command
    const { projectId, dispatch, getState, displayError } = resources
    const collaboration = ReduxSelectors.itemWithId(getState(), Collaboration, id)

    if (!isUpdateAllowed('collaboration', { changes: collaborationChanges })(getState())) return

    if (collaborationChanges.tags)
        // it's silly to have an update for the initial value of the text fields that says it was changed from ''
        // skipUpdate ||= (field === 'name' && oldValue === '') || (field === 'description' && oldValue === undefined) // TODO: re-introduce skipUpdate by handling it in the updateCollaboration()

        sendAddedTagsToSegment()

    if (collaborationChanges.categoryId) {
        Segment.sendCategoryAddedSegmentEvent(collaborationChanges.categoryId, collaboration, getState)
    }

    try {
        const changes = toUpdateFormat(collaborationChanges)
        await updateCollaboration({
            projectId,
            collaborationId: id,
            ...changes,
        })

        // If a guest has canceled their own access to a Collaboration, then the updateCollaboration above
        // will have caused an access-lost command to be received, which would remove this collaboration from Redux
        // so we check here again to make sure it's really still there before dispatching any other changes
        const collaboration = ReduxSelectors.itemWithId(getState(), Collaboration, id)
        if (!collaboration) return

        if (collaborationChanges.tags) collaborationChanges.tags = pluck('id', collaborationChanges.tags)
        if ('assigneeId' in collaborationChanges) collaborationChanges.assignee = collaborationChanges.assigneeId // properly allows null
        if ('statusId' in collaborationChanges) collaborationChanges.statusName = collaborationChanges.statusId // properly allows null
        dispatch(ReduxActions.collaborationChanged({ id, changes: collaborationChanges }))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CollaborationChangedCommand,
        CommandPlayer({
            CommandType: CollaborationChangedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { addCommand, CollaborationChangedCommand }
