import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleCanvases = {
    [SampleIds.vanderbiltPage1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: 'Map Layer Background',
    },
    [SampleIds.vanderbiltPage2]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '5451 VANDERBILT AVE-A1',
    },
    [SampleIds.thePreserveDroneDeployCanvas]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: 'Drone Deploy Map',
    },
    [SampleIds.thePreservePdfCanvasPage0]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '01 Garage',
    },
    [SampleIds.thePreservePdfCanvasPage1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '02 Floors 2 and 3',
    },
    [SampleIds.thePreservePdfCanvasPage2]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '03 Roof Plan (all floors)',
    },
    [SampleIds.thePreservePdfCanvasPage3]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '04 Elevation Plan',
    },
    [SampleIds.thePreservePdfCanvasPage4]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '05 Site Plan (Drone)',
    },
    [SampleIds.ridgedaleAveCanvas1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '2018 Lakeland',
    },
}
const vanderbiltCanvases = pick([SampleIds.vanderbiltPage1, SampleIds.vanderbiltPage2], sampleCanvases)
const ridgedaleCanvases = pick([SampleIds.ridgedaleAveCanvas1], sampleCanvases)
const thePreserveCanvases = pick(
    [
        SampleIds.thePreserveDroneDeployCanvas,
        SampleIds.thePreservePdfCanvasPage0,
        SampleIds.thePreservePdfCanvasPage1,
        SampleIds.thePreservePdfCanvasPage2,
        SampleIds.thePreservePdfCanvasPage3,
        SampleIds.thePreservePdfCanvasPage4,
    ],
    sampleCanvases
)

export { vanderbiltCanvases, ridgedaleCanvases, thePreserveCanvases }
