import SampleIds from './sample-ids.js'

// feedItems are initial feed items per project (all non Guest participants will have the same items)
// Only defined for Vanderbilt as this is the only project with collaborations
const sampleFeedItems = {
    [SampleIds.vanderbiltProject]: {
        [SampleIds.vanderbiltFeedItem1]: {
            action: 'added',
            itemType: 'Collaboration',
            itemId: SampleIds.vanderbiltCollaboration1,
            createdAt: 1664405230698,
            userId: SampleIds.jeff,
            projectId: SampleIds.vanderbiltProject,
        },
    },
    [SampleIds.thePreserveProject]: {},
    [SampleIds.ridgedaleAveProject]: {},
}

export default sampleFeedItems
