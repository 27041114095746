import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleComments = {
    [SampleIds.vanderbiltComment1]: {
        parent: SampleIds.vanderbiltCollaboration1,
        parentType: 'Collaboration',
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        text: 'Starting on the polygon',
        textIsEdited: false,
        isNote: false,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltComment2]: {
        parentType: 'Collaboration',
        parent: SampleIds.vanderbiltCollaboration1,
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        text: '@Jason is preventing me from working on this!',
        textIsEdited: true,
        isNote: true,
        completedBy: SampleIds.jeff,
        completedTimestamp: 1649228400001,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltUpload1Comment1]: {
        parentType: 'Upload',
        parent: SampleIds.vanderbiltUpload1,
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        text: 'Comment on upload',
        textIsEdited: false,
        isNote: false,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,

        // comment on upload also gets uploadId
        uploadId: SampleIds.vanderbiltUpload1,
    },
}
const vanderbiltComments = pick(
    [SampleIds.vanderbiltComment1, SampleIds.vanderbiltComment2, SampleIds.vanderbiltUpload1Comment1],
    sampleComments
)

export { vanderbiltComments }
