/*
 * Generate sample data from human-readable names rather than UUID's
 */
import Ids from './raw-sample-data/sample-ids.js'
import organizations from './raw-sample-data/sample-organizations.js'
import projects from './raw-sample-data/sample-projects.js'
import RangeStatus from './raw-sample-data/sample-range-status.js'
import { sampleAuthUserData as authUserData, sampleUsers as users } from './raw-sample-data/sample-users.js'

const sampleData = () => ({
    RangeStatus,
    organizations,
    projects,
    users,
    authUserData,
})

export { Ids, sampleData, organizations, projects, users }
