import { mergeRight, pick } from '@range.io/functional'
import { ridgedaleCanvasSources, thePreserveCanvasSources, vanderbiltCanvasSources } from './sample-canvas-sources.js'
import { ridgedaleCanvases, thePreserveCanvases, vanderbiltCanvases } from './sample-canvases.js'
import { sampleCategories } from './sample-categories.js'
import { vanderbiltCollaborations } from './sample-collaborations.js'
import { vanderbiltComments } from './sample-comments.js'
import { vanderbiltFeatures } from './sample-features.js'
import sampleFeedItems from './sample-feed-items.js'
import { vanderbiltGeometries } from './sample-geometries.js'
import SampleIds from './sample-ids.js'
import { participantKeys, suspendedParticipantKeys } from './sample-participant-keys.js'
import { ridgedalePresences, thePreservePresences, vanderbiltPresences } from './sample-presences.js'
import { ridgedaleStatusNames, thePreserveStatusNames, vanderbiltProjectStatusNames } from './sample-status-names.js'
import { ridgedaleTagNames, vanderbiltTagNames } from './sample-tag-names.js'
import { vanderbiltUpdates } from './sample-updates.js'
import { vanderbiltUploads } from './sample-uploads.js'
import { sampleUsers } from './sample-users.js'

const participant = ({ userId, role, feedItems }) =>
    mergeRight(pick(participantKeys, sampleUsers[userId]), { feedItems, organizationRole: role })

const sampleProjects = {
    [SampleIds.vanderbiltProject]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '5451 Vanderbilt Ave',
        projectType: 'RETROFIT_ID',
        description: 'Fix this house',
        address: '5451 Vanderbilt Ave',
        center: [-96.77528, 32.82478],
        organization: SampleIds.range,
        organizationName: 'Range',
        canvasOrder: [SampleIds.vanderbiltPage1, SampleIds.vanderbiltPage2],
        generatedIdentifier: '1',
        identifier: '1',
        nextCollaborationIdentifier: 4,
        canvases: vanderbiltCanvases,
        canvasSources: vanderbiltCanvasSources,
        collaborations: vanderbiltCollaborations,
        comments: vanderbiltComments,
        features: vanderbiltFeatures,
        geometries: vanderbiltGeometries,
        participants: {
            [SampleIds.jeff]: participant({
                userId: SampleIds.jeff,
                role: 'Admin',
                feedItems: sampleFeedItems[SampleIds.vanderbiltProject],
            }),
            [SampleIds.sam]: participant({
                userId: SampleIds.sam,
                role: 'Collaborator',
                feedItems: sampleFeedItems[SampleIds.vanderbiltProject],
            }),
        },
        suspendedParticipants: {
            [SampleIds.jason]: pick(suspendedParticipantKeys, sampleUsers[SampleIds.jason]),
        },
        presences: vanderbiltPresences,
        statusNames: vanderbiltProjectStatusNames,
        categories: sampleCategories.vanderbilt,
        tagNames: vanderbiltTagNames,
        updates: vanderbiltUpdates,
        uploads: vanderbiltUploads,
    },

    [SampleIds.thePreserveProject]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        center: [-122.24433843552526, 37.54342711653135],
        description: 'A reconstruction project in Redwood City, CA',
        organization: SampleIds.range,
        organizationName: 'Range',
        address: '225 Shearwater Parkway, Redwood City, California 94065, United States',
        name: 'The Preserve',
        projectType: 'ARCHITECTURE_ID',
        canvasOrder: [
            SampleIds.thePreserveDroneDeployCanvas,
            SampleIds.thePreservePdfCanvasPage0,
            SampleIds.thePreservePdfCanvasPage1,
            SampleIds.thePreservePdfCanvasPage2,
            SampleIds.thePreservePdfCanvasPage3,
            SampleIds.thePreservePdfCanvasPage4,
        ],
        generatedIdentifier: '2',
        identifier: '2',
        nextCollaborationIdentifier: 1,
        canvases: thePreserveCanvases,
        canvasSources: thePreserveCanvasSources,
        participants: {
            [SampleIds.jeff]: participant({
                userId: SampleIds.jeff,
                role: 'Admin',
                feedItems: sampleFeedItems[SampleIds.thePreserveProject],
            }),
        },
        suspendedParticipants: {
            [SampleIds.jason]: pick(suspendedParticipantKeys, sampleUsers[SampleIds.jason]),
            [SampleIds.sam]: pick(suspendedParticipantKeys, sampleUsers[SampleIds.sam]),
        },
        presences: thePreservePresences,
        statusNames: thePreserveStatusNames,
        categories: sampleCategories.thePreserve,
        tagNames: {},
    },

    [SampleIds.ridgedaleAveProject]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '5447 Ridgedale Ave',
        projectType: 'CONSTRUCTION_ID',
        description: 'Building a new house at 5447 Ridgedale Ave.',
        address: '5447 Ridgedale Ave\nDallas TX 75206',
        center: [-96.77529, 32.82596],
        organization: SampleIds.tomRa,
        organizationName: 'TomRa',
        canvasOrder: [SampleIds.ridgedaleAveCanvas1],
        generatedIdentifier: '1',
        identifier: '1',
        nextCollaborationIdentifier: 1,
        canvases: ridgedaleCanvases,
        canvasSources: ridgedaleCanvasSources,
        collaborations: {},
        comments: {},
        features: {},
        geometries: {},
        participants: {
            [SampleIds.rafal]: participant({
                userId: SampleIds.rafal,
                role: 'Admin',
                feedItems: sampleFeedItems[SampleIds.ridgedaleAveProject],
            }),
            [SampleIds.tom]: participant({
                userId: SampleIds.tom,
                role: 'Admin',
                feedItems: sampleFeedItems[SampleIds.ridgedaleAveProject],
            }),
            [SampleIds.jeff]: participant({
                userId: SampleIds.jeff,
                role: 'Admin',
                feedItems: sampleFeedItems[SampleIds.ridgedaleAveProject],
            }),
            [SampleIds.greg]: participant({ userId: SampleIds.greg, role: 'Guest' }),
        },
        presences: ridgedalePresences,
        statusNames: ridgedaleStatusNames,
        categories: sampleCategories.ridgedale,
        tagNames: ridgedaleTagNames,
    },
}

export default sampleProjects
