import { v4 } from 'uuid'
import assoc from '../ramda-like/assoc.js'
import categoryIcons from './category-icons.js'

// ---------------------------------------------------------------------------------------------------------------------
// Encode as base64 data URI
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Base64 encode the text of an SVG
 * Note: nodejs and the browser handle base64 encoding differently; this function should work in either context
 */
const encodeToBase64 = svgText => {
    if (typeof Buffer !== 'undefined') return Buffer.from(svgText).toString('base64')
    if (typeof btoa !== 'undefined') return btoa(svgText)
    throw new Error(`Can't base64 encode SVG`)
}

// Encode SVG text to base64 and then convert it to Data URI
const svgTextToDataUri = text => `data:image/svg+xml;base64,${encodeToBase64(text)}`

// ---------------------------------------------------------------------------------------------------------------------
// Raw data for default categories; order will be set from the order in this array
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const categoryData = [
    { name: 'Unassigned'      , icon: svgTextToDataUri(categoryIcons.unassigned)     , isDefault: true , order:  0 },
    { name: 'Air Conditioning', icon: svgTextToDataUri(categoryIcons.airconditioning), isDefault: false, order:  1 },
    { name: 'Concrete'        , icon: svgTextToDataUri(categoryIcons.concrete)       , isDefault: false, order:  2 },
    { name: 'Doors'           , icon: svgTextToDataUri(categoryIcons.doors)          , isDefault: false, order:  3 },
    { name: 'Earthworks'      , icon: svgTextToDataUri(categoryIcons.earthworks)     , isDefault: false, order:  4 },
    { name: 'Electrical'      , icon: svgTextToDataUri(categoryIcons.electrical)     , isDefault: false, order:  5 },
    { name: 'Exterior'        , icon: svgTextToDataUri(categoryIcons.exterior)       , isDefault: false, order:  6 },
    { name: 'Fire Safety'     , icon: svgTextToDataUri(categoryIcons.firesafety)     , isDefault: false, order:  7 },
    { name: 'Flooring'        , icon: svgTextToDataUri(categoryIcons.flooring)       , isDefault: false, order:  8 },
    { name: 'Foundation'      , icon: svgTextToDataUri(categoryIcons.foundation)     , isDefault: false, order:  9 },
    { name: 'Framing'         , icon: svgTextToDataUri(categoryIcons.framing)        , isDefault: false, order: 10 },
    { name: 'Landscaping'     , icon: svgTextToDataUri(categoryIcons.landscaping)    , isDefault: false, order: 11 },
    { name: 'Metalworks'      , icon: svgTextToDataUri(categoryIcons.metalworks)     , isDefault: false, order: 12 },
    { name: 'Painting'        , icon: svgTextToDataUri(categoryIcons.painting)       , isDefault: false, order: 13 },
    { name: 'Plumbing'        , icon: svgTextToDataUri(categoryIcons.plumbing)       , isDefault: false, order: 14 },
    { name: 'Roofing'         , icon: svgTextToDataUri(categoryIcons.roofing)        , isDefault: false, order: 15 },
    { name: 'Sheetrock'       , icon: svgTextToDataUri(categoryIcons.sheetrock)      , isDefault: false, order: 16 },
    { name: 'Windows'         , icon: svgTextToDataUri(categoryIcons.windows)        , isDefault: false, order: 17 },
]

/*
 * Return a set of default Categories.
 *
 * Returns a category for each element of categoryData
 *
 * For test purposes, to keep the resulting UUID's fixed, you can pass in a testPrefix which will be combined
 * with the order of the category to generate an Id for the Category. The testPrefix should leave off the last 12
 * characters of a UUID, because the generated UUID's are left-padded with zeros to a width of 12. With no
 * testPrefix, v4() will be called to generate the ids
 *
 * @sig defaultCategories :: UuidPrefix? -> { Id: Category }
 *
 *  UuidPrefix = UUID with final 12 characters missing, eg. '56fef848-f16c-40fa-9f4f-'     default: none
 *  Category = { name: String, icon: String, order: Number, isDefault: Boolean }
 */
const defaultCategories = testPrefix => {
    const setCategoryKeyAndOrder = (acc, category, i) => {
        const key = testPrefix ? testPrefix + String(i).padStart(12, '0') : v4()
        return assoc(key, category, acc)
    }

    return categoryData.reduce(setCategoryKeyAndOrder, {})
}

const tick = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill = "currentColor"
            d = "
                M16.7615 32
                C16.0153 32 15.4556 31.7127 14.9492 31.0696
                L8.04636 22.3398
                C7.65991 21.8609 7.5 21.4094 7.5 20.9304
                C7.5 19.8358 8.28623 19.0422 9.37896 19.0422
                C10.0319 19.0422 10.485 19.2885 10.9248 19.8495
                L16.7082 27.3751
                L27.9953 9.01254
                C28.4484 8.27366 28.9014 8 29.6477 8
                C30.7271 8 31.5 8.77993 31.5 9.86089
                C31.5 10.2851 31.3801 10.7229 31.0602 11.2155
                L18.5872 31.0285
                C18.1607 31.6853 17.5478 32 16.7615 32
                Z
            " />
    </svg>
`

const tickAsDataUri = svgTextToDataUri(tick)

export { tickAsDataUri, defaultCategories }
