import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleFeatures = {
    [SampleIds.vanderbiltFeature1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '',
        description: '',

        collaborationId: SampleIds.vanderbiltCollaboration1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltFeature2]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '',
        description: '',

        collaborationId: SampleIds.vanderbiltCollaboration2,
        geometryId: SampleIds.vanderbiltGeometry2,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltFeature3]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: '',
        description: '',

        collaborationId: SampleIds.vanderbiltCollaboration3,
        geometryId: SampleIds.vanderbiltGeometry3,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
}
const vanderbiltFeatures = pick(
    [SampleIds.vanderbiltFeature1, SampleIds.vanderbiltFeature2, SampleIds.vanderbiltFeature3],
    sampleFeatures
)

export { vanderbiltFeatures }
