import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const collaborationLocationSnapshotStorageVersion = {
    location: '1701698080090',
    'location.thumbnail0': '1701698080092',
    'location.thumbnail1': '1701698080094',
}

const sampleCollaborations = {
    [SampleIds.vanderbiltCollaboration1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        statusName: SampleIds.vanderbiltInProgressStatus,
        name: 'Repair water damage',
        description: '',
        tags: [],
        assignee: SampleIds.jeff,
        dueDateTimestamp: 1649228400000,
        locationUpdateRequestTimestamp: 1701698080090,
        storageVersion: collaborationLocationSnapshotStorageVersion,
        identifier: '1',
        followers: [SampleIds.jeff],
        permissions: [],

        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,

        categoryId: SampleIds.categoryIds.vanderbilt.Concrete,
    },
    [SampleIds.vanderbiltCollaboration2]: {
        createdAt: 1696818097066,
        lastUpdatedAt: 1696818097066,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        statusName: SampleIds.vanderbiltNeedsReviewStatus,
        name: 'Repair pipe',
        description: 'This thing needs to be repaired',
        tags: [SampleIds.tagKeyboard],
        locationUpdateRequestTimestamp: 1701698080090,
        storageVersion: collaborationLocationSnapshotStorageVersion,
        identifier: '2',
        permissions: [],

        featureId: SampleIds.vanderbiltFeature2,
        geometryId: SampleIds.vanderbiltGeometry2,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,

        categoryId: SampleIds.categoryIds.vanderbilt.Electrical,
    },
    [SampleIds.vanderbiltCollaboration3]: {
        createdAt: 1697818097066,
        lastUpdatedAt: 1697818097066,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        statusName: SampleIds.vanderbiltCompleteStatus,
        name: '',
        description: 'Description',
        tags: [SampleIds.tagKeyboard, SampleIds.tagTrackpad],
        assignee: SampleIds.sam,
        dueDateTimestamp: 1701774000000,
        locationUpdateRequestTimestamp: 1701698080090,
        storageVersion: collaborationLocationSnapshotStorageVersion,
        identifier: '3',
        followers: [],
        permissions: [],

        featureId: SampleIds.vanderbiltFeature3,
        geometryId: SampleIds.vanderbiltGeometry3,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,

        categoryId: SampleIds.categoryIds.vanderbilt.Unassigned,
    },
}
const vanderbiltCollaborations = pick(
    [SampleIds.vanderbiltCollaboration1, SampleIds.vanderbiltCollaboration2, SampleIds.vanderbiltCollaboration3],
    sampleCollaborations
)

export { vanderbiltCollaborations }
