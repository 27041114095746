import {
    Canvas,
    CanvasSource,
    Category,
    Collaboration,
    Comment,
    Feature,
    Geometry,
    Invitation,
    Organization,
    Presence,
    Project,
    StatusName,
    TagName,
    Update,
    Upload,
    User,
} from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import * as F from '@range.io/functional'
import { arrayToLookupTable, forEachObject, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Commands2 from '../commands-2/index.js'
import { mark } from '../console.js'
import {
    downloadUrlForPath,
    getDocFromFirestore,
    loadItemAndParticipants,
    loadItemsFromFirestoreCollection,
    loadItemsFromFirestoreCollectionAsLookup,
} from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { getAllDataForProject } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Load a User and the user's projects and organizations and dispatch them all to Redux
 * @sig loadUserRelatedDataIntoRedux :: (Dispatch, DatabaseApi, String) -> Output
 *   Output = { projects: [Projects], organizations: [Organizations] }
 *
 * The transformation is made tricky because the shape of the Firebase data is different from the Redux data.
 *
 * We start knowing only the id of a User, so we get that user
 * The Firebase User knows the Organizations and Projects it's permitted to see, so once we have the User,
 * we can get the Organizations and Project Summaries that the User's permissions implied
 *
 * We only want to load Project summaries up front, because we only keep one Project in memory at a time
 * (these are stored as projects/<id>/details
 */
const loadUserRelatedDataIntoRedux = async (dispatch, userId) => {
    const loadProject = async id => loadItemAndParticipants(Project, `/projects/${id}`)
    const loadOrganization = async id => loadItemAndParticipants(Organization, `/organizations/${id}`)

    const measureLoadUser = mark('LoadUser')

    dispatch(ReduxActions.loadingInitialData())

    // collect the user data along with their permissions, each of which specify a role in an Org or Project
    const userData = await getDocFromFirestore(`/users/${userId}`)
    let user = User.fromFirebase(userData)
    const { permissions } = user

    const invitations = await loadItemsFromFirestoreCollection(Invitation, `/users/${userId}/invitations`)
    user = User.addInvitations(user, invitations)

    // Now that we have the permissions, we can pull the data from Firebase projects and organizations
    // for projects, we pull only the summary data to start, since we only load on Project's details at a time

    const measureProjects = mark('LoadUserProjects')
    const projects = await Promise.all(permissions.projects.map(loadProject))
    measureProjects()

    const measureOrganizations = mark('LoadUserOrganizations')
    const organizations = await Promise.all(permissions.organizations.map(loadOrganization))
    measureOrganizations()

    // send the whole collection to Redux
    dispatch(ReduxActions.userRelatedDataLoaded({ user, organizations, projects }))

    measureLoadUser()

    return { user }
}

/*
 * Load an entire Project in at once (once only) and then attach listeners to subtrees using addFirebaseListeners
 * to listen for further changes to the Project
 */
const loadEntireProject = async (dispatch, getState, projectSummary) => {
    const measureLoadProject = mark('LoadProject')

    // no need to (a)wait for the pdfUrl
    const getCanvasSourceUrl = canvasSource => {
        // we DON'T want to send this to Firestore
        const dispatchUrl = pdfUrl =>
            dispatch(ReduxActions.canvasSourceChanged({ id: canvasSource.id, changes: { pdfUrl } }))

        // Only a PDF CanvasSource needs a pdfUrl
        if (!CanvasSource.Pdf.is(canvasSource)) return

        downloadUrlForPath(`projects/${projectId}/canvasSources/${canvasSource.id}`).then(dispatchUrl)
    }

    /*
     * Load data from the current Project's subcollection with the given leaf name (eg. 'canvasSources')
     * @sig :: loadProjectSubcollection = (Type, String) -> Promise {id: Type}
     */
    const loadProjectSubcollection = async (Type, leaf) => {
        return await loadItemsFromFirestoreCollectionAsLookup(Type, `/projects/${projectId}/${leaf}`)
    }

    // prevent a currently-loading project from reentering
    // TODO: what happens if a DIFFERENT project somehow loads WHILE this one is loading?
    if (ReduxSelectors.currentlyLoadingProject(getState()) === projectSummary) return
    dispatch(ReduxActions.projectIsLoading(projectSummary))

    // load things in this order to guarantee antecedents arrive before dependents
    const projectId = projectSummary.id
    const statusNames = await loadProjectSubcollection(StatusName, `statusNames`)

    // HACK! -- store the initialCollaborationStatus and completeCollaborationStatus; must be done BEFORE collaborations
    const statusNamesAsArray = Object.values(statusNames)
    Collaboration.initialCollaborationStatus = statusNamesAsArray.find(s => s.isInitial).id
    Collaboration.completedCollaborationStatus = statusNamesAsArray.find(s => s.isCompleted).id

    // Geometries, Features, Collaborations, Comments, Updates and Uploads are returned by getAllDataForProject
    const allDataForProject = await getAllDataForProject({ projectId })

    // TODO: save timestamp in Redux? That way we could later use getAllDataForProject with `since`
    // const { timestamp } = allDataForProject.metadata

    let { collaborations, comments, features, geometries, updates, uploads } = allDataForProject.data

    // convert Firestore data to Redux data
    collaborations = arrayToLookupTable('id', collaborations.map(Collaboration.fromFirebase))
    comments = arrayToLookupTable('id', comments.map(Comment.fromFirebase))
    features = arrayToLookupTable('id', features.map(Feature.fromFirebase))
    geometries = arrayToLookupTable('id', geometries.map(Geometry.fromFirebase))
    updates = arrayToLookupTable('id', updates.map(Update.fromFirebase))
    uploads = arrayToLookupTable('id', uploads.map(Upload.fromFirebase))

    // TagName, Users, Canvases, CanvasSources and Presences are read directly from Firestore collections (for now)
    const measureLoadSubcollections = mark('LoadSubcollections')
    const promises = [
        loadProjectSubcollection(TagName, `tagNames`),
        loadProjectSubcollection(User, `participants`),
        loadProjectSubcollection(Canvas, `canvases`),
        loadProjectSubcollection(CanvasSource, `canvasSources`),
        loadProjectSubcollection(Presence, `presences`),
        loadItemsFromFirestoreCollection(Category, `/projects/${projectId}/categories`),
    ]
    const [tagNames, users, canvases, canvasSources, presences, categories] = await Promise.all(promises)
    measureLoadSubcollections()

    const value = {
        canvases,
        canvasSources,
        categories, // [Category] (not Object)
        collaborations,
        comments,
        features,
        geometries,
        presences,
        updates,
        uploads,
        users,
        statusNames,
        tagNames,
    }

    forEachObject(getCanvasSourceUrl, canvasSources)

    // set the initial data in Redux
    dispatch(ReduxActions.projectWasLoadedFromDatabase(F.assoc('id', projectId, value)))
    dispatch(ReduxActions.selectedProjectChanged(projectSummary.id))
    dispatch(ReduxActions.mediaViewFilterSettingsReset())
    dispatch(ReduxActions.navigatorFilterSettingsReset())
    dispatch(ReduxActions.taskListFilterSettingsReset())

    measureLoadProject()
    Commands2.selectionChanged({ context: 'projectWasLoaded' })
}

// ---------------------------------------------------------------------------------------------------------------------
// Command facade: InitialDataLoadedCommand
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Get the user's basic information
 */
const InitialDataLoadedCommand = taggedSum('InitialDataLoadedCommand', {
    Outbound: { userId: StringTypes.Id },
})

const runInitialDataLoadedOutboundCommand = async (resources, command) => {
    const { dispatch } = resources
    return await loadUserRelatedDataIntoRedux(dispatch, command.userId)
}

const addInitialDataLoadedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        InitialDataLoadedCommand,
        CommandPlayer({
            CommandType: InitialDataLoadedCommand,
            runOutboundCommand: runInitialDataLoadedOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'userId',
        })
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Command facade: EntireProjectLoadedCommand
// ---------------------------------------------------------------------------------------------------------------------

const EntireProjectLoadedCommand = taggedSum('EntireProjectLoadedCommand', {
    Outbound: { projectSummary: 'Object' },
})

const runEntireProjectLoadedOutboundCommand = async (resources, command) => {
    const { dispatch, getState } = resources
    return loadEntireProject(dispatch, getState, command.projectSummary)
}

const addEntireProjectLoadedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        EntireProjectLoadedCommand,
        CommandPlayer({
            CommandType: EntireProjectLoadedCommand,
            runOutboundCommand: runEntireProjectLoadedOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'projectId',
        })
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// export
// ---------------------------------------------------------------------------------------------------------------------

export {
    EntireProjectLoadedCommand,
    InitialDataLoadedCommand,
    loadEntireProject, // for Cypress tests
    addInitialDataLoadedCommandSingleton,
    addEntireProjectLoadedCommandSingleton,
    runEntireProjectLoadedOutboundCommand,
}
