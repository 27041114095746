import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const uploadStorageVersion = {
    main: '1688523836794',
    thumbnail0: '1688523839292',
    thumbnail1: '1688523839731',
}
const vanderbiltUpload4StorageVersion = {
    annotated: '1698602545779420',
    'annotated.thumbnail0': '1698602547136583',
    'annotated.thumbnail1': '1698602547320950',
    main: '1696968400578019',
    thumbnail0: '1696968404395922',
    thumbnail1: '1696968404591955',
}

const sampleUploads = {
    [SampleIds.vanderbiltUpload1]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        correlation: SampleIds.vanderbiltUpload1,
        parent: SampleIds.vanderbiltCollaboration1,
        parentType: 'Collaboration',
        name: '',
        description: '',
        tags: [SampleIds.tagKeyboard],
        fileType: 'jpeg',
        fileSize: 241320,
        imageHeightToWidth: 1.25,
        storageVersion: uploadStorageVersion,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltUpload2]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        correlation: SampleIds.vanderbiltUpload2,
        parent: SampleIds.vanderbiltCollaboration1,
        parentType: 'Collaboration',
        name: '',
        description: '',
        tags: [SampleIds.tagKeyboard],
        fileType: 'jpeg',
        fileSize: 241320,
        imageHeightToWidth: 1.25,
        storageVersion: uploadStorageVersion,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltUpload3]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        correlation: SampleIds.vanderbiltUpload3,
        parent: SampleIds.vanderbiltCollaboration1,
        parentType: 'Collaboration',
        name: '',
        description: '',
        tags: [SampleIds.tagKeyboard],
        fileType: 'jpeg',
        fileSize: 241320,
        imageHeightToWidth: 1.25,
        storageVersion: uploadStorageVersion,

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltUpload4]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        correlation: SampleIds.vanderbiltUpload4,
        parent: SampleIds.vanderbiltCollaboration2,
        parentType: 'Collaboration',
        name: '',
        description: '',
        tags: [SampleIds.tagKeyboard],
        fileType: 'jpeg',
        fileSize: 241320,
        imageHeightToWidth: 1.25,
        storageVersion: vanderbiltUpload4StorageVersion,

        collaborationId: SampleIds.vanderbiltCollaboration2,
        featureId: SampleIds.vanderbiltFeature2,
        geometryId: SampleIds.vanderbiltGeometry2,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,

        annotations: `{
            "annotations": [
            {
                "fontSize": 62.25,
                "hexColor": "#ffffff",
                "id"      : "2324c858-fb8d-4030-8282-e0b94c12a416",
                "position": [1084, 279],
                "text"    : "Up",
                "type"    : "text"
            },
            {
                "fontSize": 83,
                "hexColor": "#000000",
                "id"      : "fc4fd5a6-b9ac-47db-9695-e30d165f3ef6",
                "position": [1050, 415],
                "text"    : "Up",
                "type"    : "text"
            },
            {
                "fontSize": 103.75,
                "hexColor": "#5d81ff",
                "id"      : "d9de3cf7-ce80-4ae5-8624-17956d4fcc03",
                "position": [1028, 586],
                "text"    : "Up",
                "type"    : "text"
            },
            {
                "fontSize": 134.875,
                "hexColor": "#f531b3",
                "id"      : "d4892248-f07b-4b7d-b8c3-934744b424cf",
                "position": [1001, 812],
                "text"    : "Up",
                "type"    : "text"
            },
            {
                "fontSize": 166,
                "hexColor": "#ff7a00",
                "id"      : "4dd2a83c-3b6a-4f4f-8427-60a482b9754f",
                "position": [976, 1088],
                "text"    : "Up",
                "type"    : "text"
            },
            {
                "endPoint"  : [1986, 913],
                "hexColor"  : "#ff7a00",
                "id"        : "8ec51bf7-5b17-43c2-af0d-4aa1b545f8a9",
                "lineWidth" : 46.6875,
                "startPoint": [1483, 896],
                "type"      : "arrow"
            },
            {
                "endPoint"  : [1841, 688],
                "hexColor"  : "#ff7a00",
                "id"        : "82f51777-a7ca-487c-b406-18edc4bed2aa",
                "lineWidth" : 31.125,
                "startPoint": [1505, 678],
                "type"      : "arrow"
            },
            {
                "endPoint"  : [1841, 500],
                "hexColor"  : "#ff7a00",
                "id"        : "7e186fac-11d5-4a98-9ce6-059cf86d7d00",
                "lineWidth" : 23.34375,
                "startPoint": [1523, 492],
                "type"      : "arrow"
            },
            {
                "endPoint"  : [1774, 340],
                "hexColor"  : "#ff7a00",
                "id"        : "bdf9b4de-7714-427a-9cbc-a880eda8bc6f",
                "lineWidth" : 15.5625,
                "startPoint": [1504, 330],
                "type"      : "arrow"
            },
            {
                "hexColor" : "#f531b3",
                "id"       : "007afbda-ce8a-45e6-b755-178b7c9202ea",
                "lineWidth": 62.25,
                    "path"     : "M470.87,1211.16 L555.87,1225.16 L674.87,1218.16",
                    "type"     : "scribble"
            },
            {
                "hexColor" : "#f531b3",
                "id"       : "fd64ca1d-cff9-4224-9bba-c8c9d072dfe7",
                "lineWidth": 46.6875,
                    "path"     : "M430.2,930.28 L515.2,944.28 L634.2,937.28",
                    "type"     : "scribble"
            },
            {
                "hexColor" : "#f531b3",
                "id"       : "ed8cc521-cd95-4aed-ab7b-c2b405b1e285",
                "lineWidth": 31.125,
                    "path"     : "M440.49,693.08 L525.49,707.08 L644.49,700.08",
                    "type"     : "scribble"
            },
            {
                "hexColor" : "#f531b3",
                "id"       : "dd5dd8dc-8981-4913-babd-fd928a390fc9",
                "lineWidth": 23.34375,
                    "path"     : "M443.5,494.7 L528.5,508.7 L647.5,501.7",
                    "type"     : "scribble"
            },
            {
                "hexColor" : "#f531b3",
                "id"       : "1ed5ea0b-8be6-4c8c-ad3a-aac841e3eebd",
                "lineWidth": 15.5625,
                    "path"     : "M424.67,342.43 L509.67,356.43 L628.67,349.43",
                    "type"     : "scribble"
            },
            {
                "endPoint"  : [1900.189111747851, 1290.606628242075],
                "hexColor"  : "#ff7a00",
                "id"        : "8d6270e7-bf5d-4bb2-8249-fd5bd07c7caa",
                "lineWidth" : 62.25,
                "startPoint": [1448.189111747851, 1290.606628242075],
                "type"      : "arrow"
            }
        ],
            "format": "https://range.io/photo-annotations/v1",
            "height": 2075,
            "scale": 5.1875,
            "width": 3130
        }`,
    },
}
const vanderbiltUploads = pick(
    [
        SampleIds.vanderbiltUpload1,
        SampleIds.vanderbiltUpload2,
        SampleIds.vanderbiltUpload3,
        SampleIds.vanderbiltUpload4,
    ],
    sampleUploads
)

export { vanderbiltUploads }
