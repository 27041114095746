import { clone } from '@range.io/functional'
import DataStore from '@range.io/mock/src/firebase/datastore.js'
import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux'
import thunk from 'redux-thunk'
import { sampleData } from '../../../firebase-tools-and-tests/raw-sample-data.js'
import { addResource } from '../firebase/commands-2/resources.js'
import CommandHistory from '../firebase/commands/command-history.js'
import {
    EntireProjectLoadedCommand,
    InitialDataLoadedCommand,
} from '../firebase/commands/initial-data-loaded-command.js'
import logStateMiddleware from './log-state-middleware.js'
import { ReduxActions, reduxReducer } from './redux-actions.js'

const createStore = () => {
    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

    const middlewares = [thunk.default ? thunk.default : thunk]
    if (typeof window !== 'undefined' && window.location.host.match(/localhost|127.0.0.1/)) {
        const message = `    Redux logging will track changes to keys you set; eg. window.reduxKeysToWatch = ['selectedUserId', 'organizations']`
        console.log(message)
        middlewares.push(logStateMiddleware)
    }

    const store = reduxCreateStore(reduxReducer, composeEnhancers(applyMiddleware(...middlewares)))
    addResource('dispatch', store.dispatch)
    addResource('getState', store.getState)

    // add a function that resets all the sample data after a useCase runs
    if (typeof window !== 'undefined' && window.Cypress) {
        window.cypressUseCaseTeardown = async () => {
            const jeffId = '3e4637e1-02f4-4fe3-b714-c281fb1ea691'
            const rangeId = 'c39f1e8b-cabb-44c9-822e-e0d7ee92a90d'
            const vanderbiltProject = '474503d1-1adc-4268-88fe-2a797148f16d'

            const commandHistory = CommandHistory(store)
            const { resourceRemoved, resourceChanged, runCommand } = commandHistory

            // reset the mock-firebase data
            window.mockFirebase.dataStore = DataStore(clone(sampleData()))

            // remove the current Firestore listeners and add them back
            resourceRemoved('userId')
            resourceRemoved('organizationId')
            resourceRemoved('projectId')
            resourceChanged('userId', jeffId)
            resourceChanged('organizationId', rangeId)
            resourceChanged('projectId', vanderbiltProject)

            // reset redux and load the user and project data
            store.dispatch(ReduxActions.reset())
            store.dispatch(ReduxActions.userLogIn()) // mark the user as logged in
            await runCommand(InitialDataLoadedCommand.Outbound(jeffId))
            await runCommand(EntireProjectLoadedCommand.Outbound({ id: vanderbiltProject }))
        }
    }

    return store
}
export default createStore
