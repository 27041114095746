import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleGeometries = {
    [SampleIds.vanderbiltGeometry1]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        annotationType: 'photoMarker',
        coordinates: JSON.stringify([-96.77546339604037, 32.82483564429168]),

        collaborationId: SampleIds.vanderbiltCollaboration1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltGeometry2]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        annotationType: 'photoMarker',
        coordinates: JSON.stringify([-96.77517058990311, 32.82476453049617]),

        collaborationId: SampleIds.vanderbiltCollaboration2,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
    [SampleIds.vanderbiltGeometry3]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        annotationType: 'photoMarker',
        coordinates: JSON.stringify([-96.77516618327404, 32.82480993019645]),

        collaborationId: SampleIds.vanderbiltCollaboration3,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
}
const vanderbiltGeometries = pick(
    [SampleIds.vanderbiltGeometry1, SampleIds.vanderbiltGeometry2, SampleIds.vanderbiltGeometry3],
    sampleGeometries
)

export { vanderbiltGeometries }
