import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const statusName = (name, color, isInitial, isCompleted, order) => ({
    createdAt: 1664405230698,
    lastUpdatedAt: 1664405230698,
    createdBy: SampleIds.jeff,
    lastUpdatedBy: SampleIds.jeff,
    name,
    color,
    isInitial,
    isCompleted,
    order,
})

// prettier-ignore
const sampleStatusNames = {
    //                                              name           color      isInitial  isCompleted  order
    [SampleIds.vanderbiltInProgressStatus]  : statusName('In Progress' , '#5D81FF', true ,     false,       0),
    [SampleIds.vanderbiltBlockedStatus]     : statusName('Blocked'     , '#EF516D', false,     false,       1),
    [SampleIds.vanderbiltNeedsReviewStatus] : statusName('Needs Review', '#8000FF', false,     false,       2),
    [SampleIds.vanderbiltCompleteStatus]    : statusName('Complete'    , '#009E5C', false,     true ,       3),
    
    [SampleIds.ridgedaleInProgressStatus]   : statusName('In Progress' , '#5D81FF', true ,     false,       0),
    [SampleIds.ridgedaleBlockedStatus]      : statusName('Blocked'     , '#EF516D', false,     false,       1),
    [SampleIds.ridgedaleNeedsReviewStatus]  : statusName('Needs Review', '#8000FF', false,     false,       2),
    [SampleIds.ridgedaleCompleteStatus]     : statusName('Complete'    , '#009E5C', false,     true ,       3),
    
    [SampleIds.thePreserveInProgressStatus] : statusName('In Progress' , '#5D81FF', true ,     false,       0),
    [SampleIds.thePreserveBlockedStatus]    : statusName('Blocked'     , '#EF516D', false,     false,       1),
    [SampleIds.thePreserveNeedsReviewStatus]: statusName('Needs Review', '#8000FF', false,     false,       2),
    [SampleIds.thePreserveCompleteStatus]   : statusName('Complete'    , '#009E5C', false,     true ,       3),
}
const vanderbiltProjectStatusNames = pick(
    [
        SampleIds.vanderbiltInProgressStatus,
        SampleIds.vanderbiltBlockedStatus,
        SampleIds.vanderbiltNeedsReviewStatus,
        SampleIds.vanderbiltCompleteStatus,
    ],
    sampleStatusNames
)
const ridgedaleStatusNames = pick(
    [
        SampleIds.ridgedaleInProgressStatus,
        SampleIds.ridgedaleBlockedStatus,
        SampleIds.ridgedaleNeedsReviewStatus,
        SampleIds.ridgedaleCompleteStatus,
    ],
    sampleStatusNames
)
const thePreserveStatusNames = pick(
    [
        SampleIds.thePreserveInProgressStatus,
        SampleIds.thePreserveBlockedStatus,
        SampleIds.thePreserveNeedsReviewStatus,
        SampleIds.thePreserveCompleteStatus,
    ],
    sampleStatusNames
)

export { vanderbiltProjectStatusNames, ridgedaleStatusNames, thePreserveStatusNames }
