/*
 * The current user's access to a collaboration changed because they are a Guest and somebody added or removed
 * their access to this collaboration somehow.
 *
 * The feed item will be access-gained or access-lost
 *
 * There's no need for outbound changes; the feed items will be changed indirectly by a Firebase Function
 * in response to an existing command, like changing the followers of a collaboration
 */

import { FeedItem } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import { loadAllItemsForCollaboration } from '../firebase-facade.js'
import CommandPlayer from './command-player.js' // ---------------------------------------------------------------------------------------------------------------------
import { runEntireProjectLoadedOutboundCommand } from './index.js'

// ---------------------------------------------------------------------------------------------------------------------
// CollaborationAccessChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CollaborationAccessChangedCommand = taggedSum('CollaborationAccessChangedCommand', {
    Inbound: { feedItem: 'FeedItem' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CollaborationAccessChangedCommand.Inbound has arrived from Firestore; send it to redux
 */
const runInboundCommand = async (resources, command) => {
    const handleAccessGained = async () => {
        const items = await loadAllItemsForCollaboration(projectId, collaborationId)
        dispatch(ReduxActions.collaborationAccessGained({ collaborationId, items }))
    }

    const handleAccessLost = async () => {
        dispatch(ReduxActions.collaborationAccessLost({ collaborationId }))

        // clear the selection if we had the now-lost collaboration selected
        const state = getState()
        if (collaborationId === state.selectedCollaborationId) {
            dispatch(ReduxActions.geometrySelectionCleared())
            mapboxDraw?.clearSelection()

            dispatch(
                ReduxActions.toastAdded({
                    id: collaborationId,
                    toastLabel: 'You no longer have access to this pin',
                    severity: 'alert',
                    showUndo: false,
                })
            )
        }
    }

    const handleBulkUpdatedProject = async () => {
        const state = getState()
        if (projectId !== state.selectedProjectId) return // if it was not the project we are on, skip

        const reloadProject = () => {
            // this actually calls loadEntireProject()
            runEntireProjectLoadedOutboundCommand(resources, { ...command, projectSummary: { id: projectId } }) // we only need to pass the id of the project
        }
        // Show the modal and reload the project
        dispatch(
            ReduxActions.globalModalDataSet({
                title: `As a result of your latest changes, we’re reloading your project to keep things up to date.`,
                description: '',
                cancelButton: {
                    label: 'Ok',
                    onClick: () => reloadProject(),
                },
            })
        )
    }

    const { dispatch, getState, mapboxDraw } = resources
    const { feedItem } = command
    const { action, itemId: collaborationId, projectId, itemType } = feedItem

    if (action === 'access-gained') return handleAccessGained()
    if (action === 'access-lost') return handleAccessLost()

    // Project
    if (itemType === 'Project' && action === 'bulk-updated') return handleBulkUpdatedProject()
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CollaborationAccessChangedCommand,
        CommandPlayer({
            CommandType: CollaborationAccessChangedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { CollaborationAccessChangedCommand, addCommand }
