import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const canvasSourceStorageVersion = { main: '1692298288611127' }

const sampleCanvasSources = {
    [SampleIds.vanderbiltMapboxCanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.vanderbiltPage1,
        name: 'Mapbox',
        type: 'mapbox',
        zoom: 18,
        center: [-96.77528, 32.82478],
        styleUrl: 'mapbox://styles/mapbox/satellite-streets-v11',
    },
    [SampleIds.vanderbiltDroneDeployCanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.vanderbiltPage1,
        name: 'DroneDeploy',
        type: 'dronedeploy',
        zoom: 17,
        center: [-122.422898, 37.746785],
        styleUrl: 'mapbox://styles/range-mapbox/clf1ibv5q000o01o5ugllamev',
        tilesetUrl:
            'https://public_tiles.dronedeploy.com/v1/tiles_images/63e6bd7ebe8b4129e27df01b/orthomosaic/{z}/{x}/{y}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjIxNDU5MTY4MDAwMDAsInVzZXJuYW1lIjoiam1jbWlubkBnbWFpbC5jb20iLCJwbGFuX2lkIjoiNjNlNmJkN2ViZThiNDEyOWUyN2RmMDFiIiwibGF5ZXIiOiJvcnRob21vc2FpYyJ9.U_CnGA-3bU7KBlqkq40ViRGDShGmO1guR1QYbBF0IT9ypWc7uPghsT8E6OXp-LhDSSbzcPLH2YQaKAiwlP4c4w',
    },
    [SampleIds.vanderbiltPdfCanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.vanderbiltPage2,
        name: '',
        type: 'pdf',
        center: [-96.77528, 32.82478],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 48,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.thePreserveDroneDeployCanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreserveDroneDeployCanvas,
        name: 'Drone Deploy',
        type: 'dronedeploy',
        zoom: 18,
        center: [-122.244338, 37.543427],
        styleUrl: 'mapbox://styles/range-mapbox/clf1ibv5q000o01o5ugllamev',
        tilesetUrl:
            'https://public_tiles.dronedeploy.com/v1/tiles_images/635d8fd156d4e1fbb514d5c4/orthomosaic/{z}/{x}/{y}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjIxNDU5MTY4MDAwMDAsInVzZXJuYW1lIjoiam1jbWlubkBnbWFpbC5jb20iLCJwbGFuX2lkIjoiNjM1ZDhmZDE1NmQ0ZTFmYmI1MTRkNWM0IiwibGF5ZXIiOiJvcnRob21vc2FpYyJ9.v95QNPBCp9OPmTXE--mgiwEX_ywGEScCbZh6hNH-EDn7sRdCQj9kbKkoDGCsyTo7usd9K4yJV-bJKooSjewXIw',
    },
    [SampleIds.thePreservePdfPage0CanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreservePdfCanvasPage0,
        name: 'page 0',
        type: 'pdf',
        center: [-122.24433843552526, 37.54342711653135],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 48,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.thePreservePdfPage1CanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreservePdfCanvasPage1,
        name: 'page 1',
        type: 'pdf',
        center: [-122.24433843552526, 37.54342711653135],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 48,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.thePreservePdfPage2CanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreservePdfCanvasPage2,
        name: 'page 3',
        type: 'pdf',
        center: [-122.24433843552526, 37.54342711653135],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 48,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.thePreservePdfPage3CanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreservePdfCanvasPage3,
        name: 'page 4',
        type: 'pdf',
        center: [-122.245, 37.544],
        isGeolocated: true,
        rotation: 30,
        userDefinedScale: 96,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.thePreservePdfPage4CanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.thePreservePdfCanvasPage4,
        name: 'page 5',
        type: 'pdf',
        center: [-122.24433843552526, 37.54342711653135],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 48,
        storageVersion: canvasSourceStorageVersion,
    },
    [SampleIds.ridgedaleAvePdfCanvasSource]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        canvas: SampleIds.ridgedaleAveCanvas1,
        name: '',
        type: 'pdf',
        center: [-96.77529, 32.82596],
        isGeolocated: false,
        rotation: 0,
        userDefinedScale: 96,
        storageVersion: canvasSourceStorageVersion,
    },
}

const vanderbiltCanvasSources = pick(
    [
        SampleIds.vanderbiltMapboxCanvasSource,
        SampleIds.vanderbiltDroneDeployCanvasSource,
        SampleIds.vanderbiltPdfCanvasSource,
    ],
    sampleCanvasSources
)

const thePreserveCanvasSources = pick(
    [
        SampleIds.thePreserveDroneDeployCanvasSource,
        SampleIds.thePreservePdfPage0CanvasSource,
        SampleIds.thePreservePdfPage1CanvasSource,
        SampleIds.thePreservePdfPage2CanvasSource,
        SampleIds.thePreservePdfPage3CanvasSource,
        SampleIds.thePreservePdfPage4CanvasSource,
    ],
    sampleCanvasSources
)

const ridgedaleCanvasSources = pick([SampleIds.ridgedaleAvePdfCanvasSource], sampleCanvasSources)

export { thePreserveCanvasSources, ridgedaleCanvasSources, vanderbiltCanvasSources }
