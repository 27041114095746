/*
 * FilterGroupMultiSelect is a base component for creating filter groups with multiple selectable options.
 * It extends FilterGroupBaseGroup and provides:
 *
 * - A searchable list of options
 * - Multi-select functionality
 * - Consistent styling and layout
 * - Support for custom option rendering
 *
 * This component is used as the foundation for various filter groups like
 * FilterGroupForAssignee, FilterGroupForTag, etc., providing a consistent multi-select experience.
 */

import React from 'react'
import { FlexColumn, FlexRow, MultiSelectPopover } from '../../../components-reusable/index.js'
import FilterGroupBaseGroup from './FilterGroupBaseGroup.js'

const FilterGroupMultiSelect = ({
    title,
    checked,
    disabled = false,
    onToggle,
    items,
    selectedItems,
    onItemSelect,
    filterItem,
    renderItem,
    renderPill,
    renderInput,
    width = 288,
    dataCy,
    css,
    maxListHeight = '600px',
    maxListWidth = '370px',
    fullPadding = false,
    variant = 'assigneeFilter',
}) => (
    <FilterGroupBaseGroup
        title={title}
        checked={checked}
        disabled={disabled}
        onToggle={onToggle}
        width={width}
        dataCy={dataCy}
        css={css}
        fullPadding={fullPadding}
    >
        <FlexColumn css={{ gap: 8 }}>
            {selectedItems?.length > 0 && (
                <FlexRow css={{ flexWrap: 'wrap', gap: 4 }}>{selectedItems.map(item => renderPill(item))}</FlexRow>
            )}
            <MultiSelectPopover
                filterItem={filterItem}
                items={items}
                onItemClick={onItemSelect}
                renderItem={renderItem}
                renderInput={renderInput}
                selectedItems={selectedItems}
                maxListHeight={maxListHeight}
                maxListWidth={maxListWidth}
                variant={variant}
            />
        </FlexColumn>
    </FilterGroupBaseGroup>
)

export default FilterGroupMultiSelect
