/* ---------------------------------------------------------------------------------------------------------------------
 * MockDocumentReference
 * ---------------------------------------------------------------------------------------------------------------------
 * https://firebase.google.com/docs/reference/js/firestore_.documentreference
 *
 * Fields
 *
 *      converter    Converter           If provided, the FirestoreDataConverter associated with this instance.
 *      firestore    Firestore           The Firestore instance the document is in. This is useful for performing transactions, for example.
 *  >>> id           String              The document's identifier within its collection; last element of path
 *      parent       CollectionReference The collection this DocumentReference belongs to (another DocumentReference)
 *  >>> path         String              A string representing the path of the referenced document (relative to the root of the database).
 *  >>> type         String              The type of this Firestore reference: always: 'document'
 *
 * No relevant functions, either static or not
 *------------------------------------------------------------------------------------------------------------------- */
import { tagged } from '@range.io/functional'

const MockDocumentReference = tagged('MockDocumentReference', {
    type: /document/,
    id: 'String',
    path: 'String',
})

export default MockDocumentReference
