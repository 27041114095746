// ---------------------------------------------------------------------------------------------------------------------
// Users
//
// don't get rid of rangehdq+emulatorUser@gmail.com; it's used as a marker in resetFirestore to prove we're using the emulator
// ---------------------------------------------------------------------------------------------------------------------
import SampleIds from './sample-ids.js'

const sampleUsers = {
    [SampleIds.emulatorUser]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.emulatorUser,
        lastUpdatedBy: SampleIds.emulatorUser,
        email: 'rangehdq+emulatorUser@gmail.com',
        firstName: 'Firebase',
        lastName: 'Emulator',
        language: 'en-US',
        timezone: 'America/Los_Angeles',
        preferredUnits: 'Imperial',
        role: 'Architect',
        phoneNumber: '1 (415) 555-0999',
        permissions: {
            organizations: [],
            projects: [],
        },
    },
    [SampleIds.jason]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.jason,
        lastUpdatedBy: SampleIds.jason,
        email: 'rangehdq+jason@gmail.com',
        firstName: 'Jason',
        lastName: 'McMinn',
        language: 'en-US',
        timezone: 'America/Los_Angeles',
        preferredUnits: 'Imperial',
        role: 'Architect',
        phoneNumber: '1 (415) 555-1000',
        permissions: {
            organizations: [],
            projects: [],
        },
    },
    [SampleIds.jeff]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        email: 'rangehdq+jeff@gmail.com',
        firstName: 'Jeff',
        lastName: 'Goldberg',
        language: 'en-US',
        timezone: 'America/Los_Angeles',
        preferredUnits: 'Imperial',
        role: 'Architect',
        phoneNumber: '1 (415) 555-1001',
        permissions: {
            organizations: [SampleIds.range, SampleIds.tomRa],
            projects: [SampleIds.vanderbiltProject, SampleIds.thePreserveProject, SampleIds.ridgedaleAveProject],
        },
    },
    [SampleIds.sam]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        email: 'rangehdq+sam@gmail.com',
        firstName: 'Sam',
        lastName: 'Atmore',
        language: 'en-NZ',
        timezone: 'Pacific/Auckland',
        preferredUnits: 'Metric',
        role: 'Architect',
        permissions: {
            organizations: [SampleIds.range],
            projects: [SampleIds.vanderbiltProject],
        },
    },
    [SampleIds.rafal]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.rafal,
        lastUpdatedBy: SampleIds.rafal,
        email: 'rangehdq+rafal@gmail.com',
        firstName: 'Rafal',
        lastName: 'Hotlos',
        language: 'en-PL',
        timezone: 'Europe/Warsaw',
        role: 'Architect',
        preferredUnits: 'Metric',
        permissions: {
            organizations: [SampleIds.tomRa],
            projects: [SampleIds.ridgedaleAveProject],
        },
    },
    [SampleIds.tom]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.tom,
        lastUpdatedBy: SampleIds.tom,
        email: 'rangehdq+tom@gmail.com',
        firstName: 'Tom',
        lastName: 'Pawlak',
        language: 'en-PL',
        timezone: 'Europe/Warsaw',
        preferredUnits: 'Metric',
        role: 'Architect',
        permissions: {
            organizations: [SampleIds.tomRa],
            projects: [SampleIds.ridgedaleAveProject],
        },
    },
    [SampleIds.greg]: {
        createdAt: 1649228400000,
        lastUpdatedAt: 1649228400000,
        createdBy: SampleIds.greg,
        lastUpdatedBy: SampleIds.greg,
        email: 'rangehdq+greg@gmail.com',
        firstName: 'Greg',
        lastName: 'Doe',
        language: 'en-PL',
        timezone: 'Europe/Warsaw',
        preferredUnits: 'Metric',
        role: 'Architect',
        permissions: {
            organizations: [SampleIds.tomRa],
            projects: [SampleIds.ridgedaleAveProject],
        },
    },
}
// prettier-ignore
const sampleAuthUserData = {
    [SampleIds.jason]: {
        displayName: 'Jason',
        email: 'rangehdq+jason@gmail.com',
        id: SampleIds.jason,
        uid: 'pRVZZxj1crTmtMwf12mbyQcWCNrD',
        password: 'jason123',
        emailVerified: true,
    },
    [SampleIds.jeff]: {
        displayName: 'Jeff',
        email: 'rangehdq+jeff@gmail.com',
        id: SampleIds.jeff,
        uid: 'J2lu0930VsPo6ZoloT7Lbq9S0GsT',
        password: 'jeff123',
        emailVerified: true,
    },
    [SampleIds.rafal]: {
        displayName: 'Rafal',
        email: 'rangehdq+rafal@gmail.com',
        id: SampleIds.rafal,
        uid: 'G6UkwB6gaUa2AfZYvfF0FcVKuPXA',
        password: 'rafal123',
        emailVerified: true,
    },
    [SampleIds.sam]: {
        displayName: 'Sam',
        email: 'rangehdq+sam@gmail.com',
        id: SampleIds.sam,
        uid: '3EIN8JGCpyrpxg3xixROla7PT3DM',
        password: 'sam123',
        emailVerified: true,
    },
    [SampleIds.tom]: {
        displayName: 'Tom',
        email: 'rangehdq+tom@gmail.com',
        id: SampleIds.tom,
        uid: 'xCX7Io03aP7bszvq8n6XKKTxWrDr',
        password: 'tom123',
        emailVerified: true,
    },
    [SampleIds.greg]: {
        displayName: 'Greg',
        email: 'rangehdq+greg@gmail.com',
        id: SampleIds.greg,
        uid: 'eb9j2FlJfZS8dG6UkhTgXxx5Nq12',
        password: 'greg123',
        emailVerified: true,
    },
}

export { sampleAuthUserData, sampleUsers }
