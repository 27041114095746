import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    FlexColumn,
    FlexRow,
    FreeRangeTextInput,
    Icon,
    ScrollArea,
    Text,
    Tooltip,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { TagManagerDeleteMultipleTagsModal, TagManagerDeleteSingleTagModal } from './TagManagerDeleteTagsModal.js'
import { TagManagerEditMultipleTagsModal, TagManagerEditSingleTagModal } from './TagManagerEditTagsModal.js'
import TagManagerListItem from './TagManagerListItem.js'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100%',
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'absolute',
    zIndex: 100,
})

const StyledFlexColumn = styled(FlexColumn, {
    minHeight: '100vh',
    width: '40%',
    minWidth: '800px',
    maxWidth: '1024px',
    justifyContent: 'start',
    paddingTop: 80,
})

const StyledTopBar = styled(FlexRow, {
    width: '100%',
})

const StyledPrimaryLabel = styled(Box, {
    width: 'auto',
    color: '$neutral04',
    fs: 32,
    fw: '700',
})

const StyledSecondaryLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 16,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledDescriptionLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 16,
    justifyContent: 'start',
    whiteSpace: 'wrap',
    pt: 16,
})

const StyledSecondaryTableLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 14,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fw: 700,
})

const StyledTagCountLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 20,
    pt: '6px',
})

const StyledTable = styled(FlexColumn, {
    width: '100%',
    background: '$neutral09',
    border: '1px solid $neutral07',
    br: 6,
    overflow: 'hidden',
})

const StyledTableHeaderCss = styled(FlexRow, {
    ai: 'center',
    height: '48px',
    gap: '8px',
    borderBottom: '1px solid $neutral07',
    background: '$neutral08',
})

const StyledTableHeaderLabel = styled(FlexRow, {
    gap: '2px',
    mr: 'auto',
})

// const StyledChevronIcon = styled(Icon, {
//     width: 12,
//     color: '$neutral05',
// })

const StyledCreateNewTagRow = styled(FlexRow, {
    ai: 'center',
    justifyContent: 'center',
    gap: '8px',
    color: '$neutral05',
    fs: 16,
    minHeight: '48px',
    transitionDuration: '0.4s',
    cursor: 'pointer',
    background: '$neutral09',
    fw: 500,

    '&:hover': {
        background: '$neutral07',
    },
})

const StyledClearInputButton = styled(Box, {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    transitionDuration: '0.4s',
    position: 'relative',
    right: '30px',

    '&:hover': {
        cursor: 'pointer',
    },
})

// Sub-components
const StyledTableHeaderColumn = ({ tableHeaderLabel }) => {
    return (
        <StyledTableHeaderLabel>
            <StyledSecondaryTableLabel>{tableHeaderLabel}</StyledSecondaryTableLabel>
            {/* <StyledChevronIcon name="chevronDown" /> */}
        </StyledTableHeaderLabel>
    )
}

const CreateNewTagRow = ({ newTagLabel, onClick }) => {
    return (
        <StyledCreateNewTagRow onClick={onClick}>
            <Icon name="tags" css={{ width: '16px', height: '16px' }} />
            Create
            <Text css={{ fw: 700, color: '$neutral04' }}>{newTagLabel}</Text>
            tag
        </StyledCreateNewTagRow>
    )
}

const ListViewEmptyArea = () => (
    <FlexColumn css={{ height: 'inherit', alignItems: 'center', justifyContent: 'center', pt: 56, pb: 40 }}>
        <Text css={{ color: '$neutral04', fs: '18px', fw: '600', lh: '24px', mb: '8px' }}>
            This project doesn’t have any tags...🤔
        </Text>
        <Text css={{ color: '$neutral05', fs: '14px', fw: '500', lh: '20px', mb: '24px' }}>
            Type your first tag into the search bar and then press enter to create your first tag.
        </Text>
    </FlexColumn>
)

/*
 * TagManager component
 */
const TagManagerFullscreen = ({ allTagNames, onAddNewTag, onClose, onDelete, onEdit, projectName, onGoToCanvas }) => {
    const [selectedTagIds, setSelectedTagIds] = useState([])
    const [filteredTags, setFilteredTags] = useState([])
    const [inputValue, setInputValue] = useState(null)
    const [actionModal, setActionModal] = useState(null)
    const inputRef = useRef()

    const updateFiltered = () => {
        const newFiltered =
            inputValue?.length > 0
                ? Object.values(allTagNames).filter(tag => tag.name.toLowerCase().includes(inputValue.toLowerCase()))
                : Object.values(allTagNames)
        const filteredAndSorted = newFiltered.sort((a, b) => a.name.localeCompare(b.name))
        setFilteredTags(filteredAndSorted)
    }

    useEffect(() => {
        updateFiltered()
    }, [allTagNames, inputValue])

    const handleSelectionChange = tagId => {
        const filteredTagIds = selectedTagIds.filter(id => id !== tagId)
        if (filteredTagIds.length === selectedTagIds.length) setSelectedTagIds([...selectedTagIds, tagId])
        else setSelectedTagIds(filteredTagIds)
    }

    const handleNewTagAddClicked = () => {
        onAddNewTag(inputValue)
        clearSearchInput()
    }

    const renderTagListItems = () => {
        const listItems = filteredTags.map((tag, idx) => (
            <TagManagerListItem
                count={tag.count}
                name={tag.name}
                onDelete={() => {
                    if (tag.count > 0) {
                        setActionModal({ type: 'delete', tagId: tag.id })
                    } else {
                        onDelete([tag.id])
                    }
                }}
                onEdit={() => setActionModal({ type: 'edit', tagId: tag.id })}
                onSelectChange={() => handleSelectionChange(tag.id)}
                selected={selectedTagIds.indexOf(tag.id) !== -1}
                key={`tag-item-${idx}`}
            />
        ))
        const possibleTag = Object.values(allTagNames).find(t => t.name === inputValue)
        const nameAlreadyExists = !!possibleTag
        const shouldShowCreateNewTag = inputValue && !nameAlreadyExists
        if (shouldShowCreateNewTag || (totalTagCount === 0 && inputValue))
            listItems.push(
                <CreateNewTagRow
                    onClick={handleNewTagAddClicked}
                    newTagLabel={inputValue || 'New Tag'}
                    key="tag-item-create"
                />
            )

        return listItems
    }

    const totalTagCount = Object.keys(allTagNames).length
    const anyTagSelected = selectedTagIds.length > 0
    const allTagsSelected = selectedTagIds.length === totalTagCount
    const isCheckboxChecked = anyTagSelected && totalTagCount > 0

    const handleInputChange = evt => {
        const newValue = evt.target.value ?? ''
        setInputValue(newValue.trim())
    }

    const handleCheckboxChange = () => {
        if (allTagsSelected) setSelectedTagIds([])
        else setSelectedTagIds(Object.keys(allTagNames))
    }

    const handleDeleteTags = tagIds => {
        onDelete(tagIds)
        setActionModal(null)
        setSelectedTagIds([]) // Clear the selection after deletion
    }

    const initiateBulkDelete = () => {
        const anyTagWithInstances = selectedTagIds.some(tagId => allTagNames[tagId].count > 0)
        if (anyTagWithInstances) {
            setActionModal('deleteMany')
        } else {
            handleDeleteTags(selectedTagIds) // Use handleDeleteTags to manage deletion and state update
        }
    }

    const handleEditTags = (actionItem, tagIds = []) => {
        onEdit(actionItem, tagIds)
        setActionModal(null)
        if (tagIds.length > 0) setSelectedTagIds([])
    }

    const renderActionModal = () => {
        if (actionModal === 'deleteMany') {
            const totalInstanceCount = selectedTagIds.reduce((acc, tagId) => acc + allTagNames[tagId].count, 0)
            return (
                <TagManagerDeleteMultipleTagsModal
                    instanceCount={totalInstanceCount}
                    onCancel={() => setActionModal(null)}
                    onDelete={() => handleDeleteTags(selectedTagIds)}
                />
            )
        } else if (actionModal.type === 'delete') {
            return (
                <TagManagerDeleteSingleTagModal
                    instanceCount={allTagNames[actionModal.tagId].count}
                    onCancel={() => setActionModal(null)}
                    onDelete={() => handleDeleteTags([actionModal.tagId])}
                />
            )
        } else if (actionModal.type === 'edit') {
            return (
                <TagManagerEditSingleTagModal
                    allTags={allTagNames}
                    onCancel={() => setActionModal(null)}
                    onSave={actionItem => handleEditTags(actionItem, [actionModal.tagId])}
                    selectedTagId={actionModal.tagId}
                />
            )
        } else if (actionModal === 'editMany') {
            return (
                <TagManagerEditMultipleTagsModal
                    allTags={allTagNames}
                    onCancel={() => setActionModal(null)}
                    onSave={actionItem => handleEditTags(actionItem, selectedTagIds)}
                    selectedTagIds={selectedTagIds}
                />
            )
        }
    }

    const clearSearchInput = () => {
        setInputValue(null)
        inputRef.current.value = ''
    }

    const handleKeyDownInInput = evt => {
        if (evt.key === 'Enter') {
            const possibleTag = Object.values(allTagNames).find(t => t.name === inputValue)
            const nameAlreadyExists = !!possibleTag

            if (!nameAlreadyExists && inputValue) {
                handleNewTagAddClicked()
            } else {
                inputRef.current.blur()
            }
        }
    }

    const tagsSelectedLabel = selectedTagIds.length === 1 ? '1 tag selected' : `${selectedTagIds.length} tags selected`
    const tagsDeletedLabel = selectedTagIds.length === 1 ? 'Delete Tag' : `Delete Tags`
    const tagsEditLabel = selectedTagIds.length === 1 ? 'Edit Tag' : `Edit Tags`
    const hasSearchQuery = inputValue && inputValue.length > 0

    const isViewEmpty = totalTagCount === 0

    return (
        <StyledOuterContainer>
            {actionModal && renderActionModal()}
            <StyledFlexColumn>
                <StyledTopBar>
                    <FlexColumn css={{ width: 'inherit' }}>
                        <Button onClick={onClose} css={{ width: '70px', mb: '40px' }} variant="primary" size="lg">
                            <Text>Close</Text>
                        </Button>
                        <FlexRow css={{ ai: 'center', gap: 8, width: '100%', pt: 4 }}>
                            <Icon css={{ color: '$neutral04' }} name="tags" iconSize="32" />
                            <StyledPrimaryLabel>Tag Manager</StyledPrimaryLabel>
                            <StyledTagCountLabel>({totalTagCount})</StyledTagCountLabel>
                        </FlexRow>
                        <StyledDescriptionLabel>
                            Tags help organize similar pins and content. Use filters to isolate content with the same
                            tags. Both pins and media (photos) can be assigned one or more tags. Tags are unique to each
                            Range project.
                        </StyledDescriptionLabel>
                    </FlexColumn>
                </StyledTopBar>
                <FlexColumn css={{ gap: 16, width: '100%' }}>
                    <FlexRow css={{ width: 'inherit', gap: 16, mt: '32px', justifyContent: 'space-between' }}>
                        <FlexRow css={{ alignItems: 'center' }}>
                            <FreeRangeTextInput
                                variant="default"
                                css={{
                                    width: '300px',
                                    maxWidth: '400px',
                                    fw: 500,
                                    fs: 16,
                                    height: 40,
                                }}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDownInInput}
                                placeholder="Search for, or create a tag..."
                                ref={inputRef}
                            />
                            {hasSearchQuery && (
                                <Tooltip tooltipText="Clear" side="right" align="center">
                                    <StyledClearInputButton onClick={clearSearchInput}>
                                        <Icon name="close" css={{ size: '9px', color: '$neutral05' }} />
                                    </StyledClearInputButton>
                                </Tooltip>
                            )}
                        </FlexRow>

                        {/* Hide styledBulkActions when no list items have been selected using the checkbox */}
                        {anyTagSelected && (
                            <FlexRow css={{ gap: 16, ai: 'center', justifyContent: 'end' }}>
                                <StyledSecondaryLabel>{tagsSelectedLabel}</StyledSecondaryLabel>
                                <Button variant="secondary" size="lg" onClick={() => setActionModal('editMany')}>
                                    <Icon name="edit" iconSize="16px" />
                                    <Text>{tagsEditLabel}</Text>
                                </Button>
                                <Button variant="destructiveSecondary" size="lg" onClick={initiateBulkDelete}>
                                    <Icon name="trash" iconSize="16px" />
                                    <Text>{tagsDeletedLabel}</Text>
                                </Button>
                            </FlexRow>
                        )}
                    </FlexRow>
                    <StyledTable>
                        <StyledTableHeaderCss>
                            <Box css={{ ml: '16px', mr: '8px' }}>
                                <Checkbox
                                    checked={isCheckboxChecked}
                                    onCheckedChange={handleCheckboxChange}
                                    partiallyChecked={
                                        selectedTagIds.length > 0 && selectedTagIds.length < totalTagCount
                                    }
                                    size="md"
                                />
                            </Box>
                            {/* Ideally we can reorder the tag list by name or instances */}
                            <StyledTableHeaderColumn tableHeaderLabel="Tag Name" />
                            <StyledTableHeaderColumn tableHeaderLabel="Instances" />
                        </StyledTableHeaderCss>
                        <FlexColumn css={{ maxHeight: '50vh' }}>
                            {isViewEmpty && !inputValue ? (
                                <ListViewEmptyArea onGoToCanvas={onGoToCanvas} />
                            ) : (
                                <ScrollArea>{renderTagListItems()}</ScrollArea>
                            )}
                        </FlexColumn>
                    </StyledTable>
                </FlexColumn>
            </StyledFlexColumn>
        </StyledOuterContainer>
    )
}

TagManagerFullscreen.displayName = 'TagManagerFullscreen'

TagManagerFullscreen.propTypes = {
    allTagNames: PropTypes.object.isRequired,
    onAddNewTag: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    projectName: PropTypes.string,
}

export default TagManagerFullscreen
