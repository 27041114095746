import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleTagNames = {
    [SampleIds.tagKeyboard]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,

        name: 'keyboard',
        isHidden: false,
    },
    [SampleIds.tagTrackpad]: {
        createdAt: 1664405230698,
        lastUpdatedAt: 1664405230698,
        createdBy: SampleIds.jeff,
        lastUpdatedBy: SampleIds.jeff,
        name: 'trackpad',
        isHidden: false,
    },
}
const vanderbiltTagNames = pick([SampleIds.tagKeyboard, SampleIds.tagTrackpad], sampleTagNames)
const ridgedaleTagNames = pick([SampleIds.tagKeyboard, SampleIds.tagTrackpad], sampleTagNames)

export { vanderbiltTagNames, ridgedaleTagNames }
