/*
 * A "simple" Mapbox box that:
 *
 * - shows 0 or 1 "place" (point + name)
 * - optionally allows the user to click to geocode a new place
 * - optionally allows the user to enter a place name into a Mapbox search box and geocode its point
 *
 *
 * mapCenter        specifies the initial Mapbox center (before the user pans)
 * mapZoom          specifies the initial Mapbox zoom (before the user zooms)
 * place            specifies a Place
 * setPlace         optional callback when the user has changed the place by clicking the map or geocoding a place name
 * css              optional css to wrap the map with
 *
 * Type
 *
 * XYArray          [lng, lat]
 * Place            { point: XYArray, name: String }
 */
import { MapboxSearchBox } from '@mapbox/search-js-web'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '../range-theme/index.js'

/* global fetch */

// ---------------------------------------------------------------------------------------------------------------------
// Mapbox setup
// ---------------------------------------------------------------------------------------------------------------------
const accessToken = 'pk.eyJ1IjoicmFuZ2UtbWFwYm94IiwiYSI6ImNsZW9tdjFyZDAwZ3Azd25xYW9lbmdyNjUifQ.XwN5HIELuR-mEhA_i_z5XA'
mapboxgl.accessToken = accessToken

const StyledMap = styled('div', {
    // defeat Mapbox's test to see if you've loaded mapboxgl.css -- which we haven't
    '.mapboxgl-canary': {
        backgroundColor: 'rgb(250, 128, 114)',
    },

    // this will be valid ONLY if the search box is within the map (that is, no searchBoxContainerId was specified)
    'mapbox-search-box': {
        position: 'absolute',
        top: 16,
        left: 391, // sidebar is 375 + 16
        width: 400,
        border: '1px solid $neutral07',
        borderRadius: 6,
        zIndex: 10,

        '& [class$="--SearchBox"]': {
            input: {
                outline: 'none',
            },
        },
    },
})

const MAPBOX_STYLE = 'mapbox://styles/range-mapbox/cleoron2t000401qryhl9exx2'
const MAPBOX_SOURCE_ID = 'points'
const MAPBOX_LAYER_ID = 'marker'

/*
 * Create the GeoJSON for a Mapbox Source with 0 or 1 point depending on whether markerPoint is defined
 * The source will be updated later if the marker changes via source.setData
 * @sig mapboxSource :: XYArray? -> GeoJSON
 */
const mapboxSource = place => ({
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: place?.point ? [{ type: 'Feature', geometry: { type: 'Point', coordinates: place.point } }] : [],
    },
})

/*
 * Create a Mapbox Layer to display a marker with the markerTitle
 * @sig mapboxLayer :: String -> MapboxLayerJSON
 */
const mapboxLayer = place => ({
    id: MAPBOX_LAYER_ID,
    type: 'symbol',
    source: MAPBOX_SOURCE_ID,
    layout: {
        'icon-image': 'custom-marker',
        'icon-anchor': 'bottom',
        'text-field': place?.name || '',
        'text-font': ['Arial Unicode MS Bold'],
        'text-offset': [0, 0],
        'text-anchor': 'top',
    },
    paint: {
        'text-color': '#ffffff',
        'text-halo-color': 'hsl(0, 5%, 0%)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// StaticMap
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Convert ReverseGeocodedData into a Place
 * see https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
 */
const createPlaceFromReverseGeocode = data => {
    const name = data?.features?.[0]?.place_name
    return name ? { point: data.query, name } : undefined
}

/*
 * Return a Geocode result from Mapbox's Geocoding REST API (https://docs.mapbox.com/api/search/geocoding/)
 * @sig reverseGeocode :: XYArray -> Promise Place
 */
const reverseGeocode = async point =>
    new Promise((resolve, reject) => {
        const [lng, lat] = point
        const s = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
        fetch(s)
            .then(response => response.json())
            .then(data => resolve(createPlaceFromReverseGeocode(data)))
            .catch(reject)
    })

/*
 * Wrap a Mapbox map in a React component
 * if setMarkerPoint is specified the map will call it when the user clicks
 */
const SinglePlaceMap = ({ mapCenter, mapZoom, place, setPlace, css = {}, searchBoxContainerId }) => {
    const _setPlace = place => {
        mapboxRef.current.flyTo({ center: place.point, zoom: 18, speed: 3 })
        setPlace(place)
    }

    const addSearchBoxToContainer = searchBox => {
        const searchContainer = document.getElementById(searchBoxContainerId)
        if (!searchContainer) return

        searchContainer.innerHTML = '' // Clear any existing content
        searchContainer.appendChild(searchBox)
    }

    /*
     * The user clicked somewhere on the map to set the Map's "place" (possibly because they're building a new
     * site that doesn't yet have an address they can look up).
     *
     * Reverse geocode the clicked point to give the Map's "place" a name
     */
    const findPlaceAtClick = async e => {
        const point = e.lngLat.toArray()
        const place = await reverseGeocode(point)
        if (place) _setPlace(place)
    }

    /*
     * Initialize a Mapbox SearchBox
     *
     * when the user types into the search box, Mapbox finds (usually 5) matches and calls a 'suggest' handler
     * before showing a dropdown of the suggestions.
     *
     * When the user selects among the suggestions, Mapbox calls a 'retrieve' handler with place data
     *
     * We don't need a 'suggest' handler, but we do need to set our "place" on 'retrieve'
     */
    const createSearchBox = () => {
        // Create a new MapboxSearchBox element
        const searchBox = new MapboxSearchBox()

        // Configure the search box
        searchBox.accessToken = accessToken
        searchBox.options = { language: 'en' }
        searchBox.marker = true
        searchBox.mapboxgl = mapboxgl
        searchBox.placeholder = 'Enter an address'

        searchBox.addEventListener('retrieve', event => handleSearchBoxRetrieve(event.detail))
        return searchBox
    }

    /*
     * See the comment for createSearchBox; update the map's "place" following a SearchBox search
     */
    const handleSearchBoxRetrieve = result => {
        const mapboxMap = mapboxRef.current

        if (!mapboxMap?._loaded) return // should never happen, since we only added the component on 'load'

        const features = result.features

        if (!features || features.length === 0) return

        const { geometry, properties } = features[0]
        const point = geometry?.coordinates
        const name = properties?.place_name || properties?.name

        _setPlace({ point, name })
    }

    /*
     * If setPlace is a function, the user will be able to change the location specified by the map,
     * either by clicking on a spot (after which we do a reverse geocoding to get the address),
     * or by specifying an address/POI in the search box control.
     *
     * If setPlace is not a function, the map is "read-only"
     */
    const initializeHandlersForSetPlace = mapboxMap => {
        /*
         * IF we passed in a searchBoxContainerId, then we DON'T want to add the SearchBox as a mapbox "control"
         * Instead we want to put the SearchBox inside the element identified by the searchBoxContainerId.
         * AND: the CSS *styling* of the SearchBox is controlled by the CALLER, not by the SinglePlaceMap.
         *
         * Otherwise, if we didn't pass in a searchBoxContainerId, we can just add the SearchBox as a control,
         * and the styling comes from the StyledMap above
         */
        if (searchBoxContainerId) addSearchBoxToContainer(createSearchBox())
        else mapboxMap.addControl(createSearchBox())

        mapboxMap.on('click', findPlaceAtClick)
    }

    /*
     * We display the (one) marker shown by the Map in its own Mapbox layer; we wait for the marker icon
     * to load before creating this layer and its source.
     *
     * We'll update the source data if the Map's "place" changes
     */
    const addOurCustomMarkerLayer = mapboxMap => (error, image) => {
        if (error) throw error

        if (!mapboxMap) return // (happens in Cypress tests in CI/CD sometimes)

        mapboxMap.addImage('custom-marker', image)
        mapboxMap.addSource(MAPBOX_SOURCE_ID, mapboxSource(place))
        mapboxMap.addLayer(mapboxLayer(place))
    }

    // mapbox loaded: add a custom layer to display a marker and set up the Search Box
    const onLoadMap = () => {
        const mapboxMap = mapboxRef.current

        mapboxMap.loadImage('/pin.projectlocation.png', addOurCustomMarkerLayer(mapboxMap))
        if (setPlace) initializeHandlersForSetPlace(mapboxMap)
    }

    // user dragged/zoomed
    const onMoveMap = () => {
        setCenter(mapboxRef.current.getCenter().toArray())
        setZoom(mapboxRef.current.getZoom())
    }

    // if the marker's location and/or title change, update our custom Mapbox layer's source data
    const onMovePlace = () => {
        const mapboxMap = mapboxRef.current

        if (!mapboxMap?._loaded) return // can be called too early by useEffect

        const source = mapboxRef.current.getSource(MAPBOX_SOURCE_ID)
        if (!source) return

        source.setData(mapboxSource(place).data) // change the location
        mapboxRef.current.setLayoutProperty(MAPBOX_LAYER_ID, 'text-field', place.name) // change the name
    }

    // called once via useEffect
    const initializeMapbox = () => {
        if (mapboxRef.current) return // initialize map only once

        // create the map and add listeners
        const mapOptions = {
            container: mapContainer.current,
            style: MAPBOX_STYLE,
            center,
            zoom,
            attributionControl: false,
        }

        mapboxRef.current = new mapboxgl.Map(mapOptions)
        mapboxRef.current.on('move', onMoveMap)
        mapboxRef.current.on('load', onLoadMap)

        if (window.Cypress) window.mapboxMap = mapboxRef.current

        // for cleanup on unmount
        return () => {
            mapboxRef.current.remove()
            mapboxRef.current = undefined
        }
    }

    const mapContainer = useRef(null)
    const mapboxRef = useRef(null)
    const [center, setCenter] = useState(mapCenter)
    const [zoom, setZoom] = useState(mapZoom)

    useEffect(initializeMapbox, [])
    useEffect(onMovePlace, [place])

    return <StyledMap style={css} ref={mapContainer} />
}

const PlaceType = PropTypes.shape({
    name: PropTypes.string,
    point: PropTypes.arrayOf(PropTypes.number),
})

SinglePlaceMap.propTypes = {
    mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
    mapZoom: PropTypes.number.isRequired,
    place: PlaceType,
    setPlace: PropTypes.func,
    css: PropTypes.object,
    searchBoxContainerId: PropTypes.string,
}

export default SinglePlaceMap
