/*
 * AdminViewSubscriptionBlock: Display the subscription status of the current Organization:
 *
 * - which plan
 * - how much / month
 * - how many seats you've paid and how many you're currently using
 * - a Manage button to change your subscription
 *
 * AdminViewManageSubscriptionModal: modal to change your seat count up or down
 *
 * Clicking on "Manage" in the AdminViewSubscriptionBlock will show the AdminViewManageSubscriptionModal
 *
 */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useStore } from 'react-redux'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import Icon from '../components-reusable/Icon.js'
import { Box, Button, FlexColumn, FlexRow, IconButtonWithTooltip, Text } from '../components-reusable/index.js'
import { formatUsd, pricingMetrics, RangePlans } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions } from '../redux/index.js'
import StripeHelpers from '../stripe-helpers.js'
import AdminViewUpgradePage from './AdminViewUpgradePage.js'
import { StyledModalActions, StyledModalContainer, StyledOverlay } from './Modal.js'

const MainContainer = styled(FlexColumn, {
    w: '100%',
    br: 5,
    b: '1px solid $neutral07',
    p: 16,
    bg: '$neutral09',
    color: '$neutral04',
    fs: 18,
    fw: 500,
    fontName: 'inter',
})

const InnerBox = styled(Box, {
    b: '1px solid $neutral07 !important',
    p: '12px 20px 12px 16px',
    w: '33%',
    br: 6,
    background: '$neutral10',
})

const OutlineButton = styled(Button, {
    bc: '$neutral05',
})

const SmallText = styled(Text, {
    fs: 14,
    fw: 400,
    color: '$neutral05',
})

const LargeText = styled(Text, {
    fs: 32,
    fw: 700,
})

const SubscriptionSeatsWarning = () => (
    <Text
        css={{
            p: '12px 16px',
            br: 6,
            bg: '$neutral09',
            fs: '14px',
            fw: 400,
            color: '$neutral04',
            b: '1px solid $neutral07',
        }}
    >
        👉 This is a reminder that your account is charged prorated amount for any additional seats added during your
        billing cycle.
    </Text>
)

// ---------------------------------------------------------------------------------------------------------------------
// AdminViewManageSubscriptionModal
// ---------------------------------------------------------------------------------------------------------------------

const RoundButton = ({ tooltipText, color = '$neutral04', iconName, onClick, disabled }) => (
    <IconButtonWithTooltip
        side="bottom"
        size="38"
        iconName={iconName}
        tooltipText={tooltipText}
        onClick={onClick}
        disabled={disabled}
        css={{
            h: 40,
            w: 40,
            br: 999,
            b: '1px solid $neutral07',
            bg: '$neutral09',
            color,
            '&:disabled': {
                cursor: 'not-allowed',
                color: '$neutral06',
            },
        }}
    />
)

const DecrementButton = ({ seatCount, assignedSeats, onClick }) => {
    const isAtMinimumSeats = seatCount <= StripeHelpers.MIN_SEATS_COUNT
    const cantDecrement = seatCount <= assignedSeats || isAtMinimumSeats

    const color = cantDecrement ? '$neutral05' : '$neutral04'

    let tooltip = 'Fewer seats'
    if (isAtMinimumSeats) tooltip = `Range Premium requires a minimum of ${StripeHelpers.MIN_SEATS_COUNT} seats`
    else if (cantDecrement)
        tooltip =
            'To reduce your total seat count, you must first unassign any members from the seats you’d like to remove. Do this by suspending Admins and Collaborators or cancelling existing invitations.'

    return (
        <RoundButton
            tooltipText={tooltip}
            iconName="zoomOut"
            color={color}
            onClick={onClick}
            disabled={cantDecrement}
        />
    )
}

const IncrementButton = ({ onClick }) => <RoundButton tooltipText="More seats" iconName="zoomIn" onClick={onClick} />

/*
 * Modal to change your subscription: - count +
 * Includes buttons for Downgrade Plan, Cancel and Confirm
 *
 * @sig AdminViewManageSubscriptionModal :: ({ String, Number, Number, onCancel, onConfirm: int -> *, onDowngrade: () -> * }) => {
 */
const AdminViewManageSubscriptionModal = ({
    organizationName,
    currentSeatCount,
    minimumSeatCount,
    assignedSeats,
    onCancel,
    onConfirm,
    onDowngrade,
}) => {
    const increment = () => setSeatCount(seatCount + 1)
    const decrement = () => {
        const canDecrement = seatCount > assignedSeats
        if (canDecrement) setSeatCount(seatCount - 1)
    }

    // n seat|seats added|removed
    const seatsAddedText = () => {
        const seatsAdded = seatCount - currentSeatCount
        if (seatsAdded < -1) return `${-seatsAdded} seats removed`
        if (seatsAdded === -1) return '1 seat removed'
        if (seatsAdded === 0) return '0 seats added'
        if (seatsAdded === 1) return '1 seat added'
        if (seatsAdded > 1) return `${seatsAdded} seats added`
    }

    const confirm = () => onConfirm(seatCount)

    // -----------------------------------------------------------------------------------------------------------------
    // main
    // -----------------------------------------------------------------------------------------------------------------

    const [seatCount, setSeatCount] = useState(minimumSeatCount)

    // 2024.09.10 deleted "Downgrade Plan" button for now; we'll do it manually now

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer css={{ bg: '$neutral10', w: 560 }}>
                <FlexColumn css={{ p: 24, bg: '$neutral10', gap: 10 }}>
                    <FlexRow css={{ ai: 'center', gap: 8 }}>
                        <Icon iconSize="20" name="seat" />
                        <Text css={{ fs: 18, fw: 500 }}>Manage Seats</Text>
                    </FlexRow>
                    <SubscriptionSeatsWarning />
                    <FlexRow>
                        <Text css={{ textAlign: 'center', pt: 4, pb: 4, width: '100%' }}>
                            Current total seats for the {organizationName} Organization
                        </Text>
                    </FlexRow>
                    <FlexRow css={{ ai: 'center' }}>
                        <DecrementButton seatCount={seatCount} assignedSeats={assignedSeats} onClick={decrement} />
                        <FlexColumn
                            css={{
                                ml: 16,
                                mr: 16,
                                p: '8px 16px 12px 16px',
                                bg: '$neutral09',
                                br: 6,
                                b: '1px solid $neutral07',
                                ai: 'center',
                                w: '100%',
                            }}
                        >
                            <LargeText>{seatCount}</LargeText>
                            <SmallText>{seatsAddedText()}</SmallText>
                        </FlexColumn>
                        <IncrementButton onClick={increment} />
                    </FlexRow>
                </FlexColumn>

                <StyledModalActions>
                    <Button css={{ h: 40, ml: 'auto' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ h: 40 }} variant="primary" size="lg" onClick={confirm}>
                        Confirm
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const InfoCard = ({ title, value, adminString, collaboratorString, manageButton, onManageClick }) => (
    <InnerBox>
        <SmallText>{title}</SmallText>
        <FlexRow css={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <FlexRow css={{ alignItems: 'center' }}>
                <LargeText>{value}</LargeText>
                {adminString && collaboratorString && (
                    <FlexColumn css={{ marginLeft: 4 }}>
                        <Text css={{ fs: 12, lineHeight: 'normal' }}>{adminString}</Text>
                        <Text css={{ fs: 12, lineHeight: 'normal' }}>{collaboratorString}</Text>
                    </FlexColumn>
                )}
            </FlexRow>
            {manageButton && (
                <OutlineButton variant="manage" size="md" onClick={onManageClick}>
                    Manage
                </OutlineButton>
            )}
        </FlexRow>
    </InnerBox>
)

const PlanHeader = ({ plan, buttonConfig, perSeat, showPrice = true, showPerSeatText = false }) => (
    <FlexColumn>
        <FlexRow css={{ gap: 10, alignItems: 'center' }}>
            <Text>Range {plan} Plan</Text>
            {buttonConfig.map(({ text, variant, onClick }, index) => (
                <Button css={{ pl: 10, pr: 10 }} key={index} variant={variant} size="md" onClick={onClick}>
                    {text}
                </Button>
            ))}
        </FlexRow>

        {showPrice && <LargeText>${perSeat}</LargeText>}
        {showPerSeatText && <SmallText>per seat per month</SmallText>}
    </FlexColumn>
)

const FreeOrganizationBlock = ({
    adminString,
    collaboratorString,
    assignedSeats,
    unassignedSeats,
    seatCount,
    plan,
    openModal,
}) => {
    return (
        <MainContainer>
            <PlanHeader
                plan={plan}
                buttonConfig={[{ text: 'Upgrade to Team', variant: 'primary', onClick: openModal }]}
                showPrice={false}
            />

            <FlexRow css={{ paddingTop: 16, gap: 16 }}>
                <InfoCard title="Total Seats" value={seatCount} />
                <InfoCard
                    title="Assigned Seats"
                    value={assignedSeats}
                    adminString={adminString}
                    collaboratorString={collaboratorString}
                />
                <InfoCard title="Available Seats" value={unassignedSeats} />
            </FlexRow>
        </MainContainer>
    )
}
const TeamOrganizationBlock = ({
    adminString,
    collaboratorString,
    annualPrice,
    billingCycle,
    monthlyPrice,
    nextPaymentTimestamp,
    assignedSeats,
    unassignedSeats,
    seatCount,
    plan,
    openModal,
    visitCustomerPortal,
}) => {
    const metrics = pricingMetrics(plan, billingCycle, monthlyPrice, annualPrice, seatCount)
    const { isDiscounted, percentageDiscount, finalPrice, perSeat } = metrics

    const billingText = `${formatUsd(finalPrice)} billed ${
        isDiscounted ? `annually (${percentageDiscount}% discount)` : 'monthly'
    }`

    return (
        <MainContainer>
            <FlexRow>
                <PlanHeader
                    plan={plan}
                    buttonConfig={[{ text: 'Manage Billing', variant: 'outline', onClick: visitCustomerPortal }]}
                    perSeat={perSeat}
                    showPerSeatText={true}
                />

                <FlexColumn css={{ marginLeft: 'auto', alignItems: 'flex-end', fontWeight: 700 }}>
                    <FlexRow css={{ minHeight: '32px', alignItems: 'center' }}>
                        <SmallText>
                            Next invoice on{' '}
                            {nextPaymentTimestamp.toLocaleDateString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </SmallText>
                    </FlexRow>
                    <LargeText>{seatCount} Seats</LargeText>
                    <SmallText>{billingText}</SmallText>
                </FlexColumn>
            </FlexRow>
            <FlexRow css={{ paddingTop: 16, gap: 16 }}>
                <InfoCard title="Total Seats" value={seatCount} manageButton onManageClick={openModal} />
                <InfoCard
                    title="Assigned Seats"
                    value={assignedSeats}
                    adminString={adminString}
                    collaboratorString={collaboratorString}
                />
                <InfoCard title="Available Seats" value={unassignedSeats} manageButton onManageClick={openModal} />
            </FlexRow>
        </MainContainer>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// AdminViewSubscriptionBlock
// ---------------------------------------------------------------------------------------------------------------------

const AdminViewSubscriptionBlock = ({
    organization,
    monthlyPrice,
    annualPrice,
    admins,
    collaborators,
    minimumSeatCount,
    changeSeatCount,
    downgrade,
    upgrade,
    visitCustomerPortal,
}) => {
    const { dispatch } = useStore()

    const { name: organizationName, plan, billingCycle, nextPaymentTimestamp, seatCount } = organization

    const plural = (count, suffix) => (count <= 1 ? `${count} ${suffix}` : `${count} ${suffix}s`)

    const getSeatsAddedText = (currentSeatCount, newSeatCount) => {
        const seatsAdded = newSeatCount - currentSeatCount
        if (seatsAdded < -1) return `${-seatsAdded} seats removed from your Organization`
        if (seatsAdded === -1) return '1 seat removed from your Organization'
        if (seatsAdded === 1) return '1 seat added to your Organization'
        if (seatsAdded > 1) return `${seatsAdded} seats added to your Organization`
    }

    const changeSeatCountAndClose = async count => {
        closeModal()

        if (count !== seatCount) {
            try {
                const { seatCount: newSeatCount } = await changeSeatCount(count)

                const toastLabel = getSeatsAddedText(seatCount, newSeatCount)
                dispatch(
                    ReduxActions.toastAdded({
                        id: 'stripe-toast',
                        toastLabel,
                        severity: 'success',
                        showUndo: false,
                    })
                )
            } catch (e) {
                dispatch(
                    ReduxActions.toastAdded({
                        id: 'stripe-toast',
                        toastLabel: 'Something went wrong',
                        severity: 'error',
                        showUndo: false,
                    })
                )
            }
        }
    }

    const downgradeAndClose = () => {
        closeModal()
        downgrade()
    }

    const upgradeAndClose = (billingCycle, seatCount) => {
        closeModal()
        upgrade(billingCycle, seatCount)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // main
    // -----------------------------------------------------------------------------------------------------------------

    const adminString = plural(admins, 'Admin')
    const collaboratorString = plural(collaborators, 'Collaborator')

    const { get: showModal, set: openModal, unset: closeModal } = useBooleanState(false)

    const assignedSeats = admins + collaborators
    const unassignedSeats = seatCount - assignedSeats
    const isPaid = plan !== RangePlans.Free

    return (
        <>
            {showModal() && isPaid && (
                <AdminViewManageSubscriptionModal
                    organizationName={organizationName}
                    currentSeatCount={seatCount}
                    minimumSeatCount={minimumSeatCount}
                    assignedSeats={assignedSeats}
                    onCancel={closeModal}
                    onConfirm={changeSeatCountAndClose}
                    onDowngrade={downgradeAndClose}
                />
            )}
            {showModal() && !isPaid && (
                <AdminViewUpgradePage
                    annualPrice={annualPrice}
                    monthlyPrice={monthlyPrice}
                    onClose={closeModal}
                    onContinueToPayment={upgradeAndClose}
                    minimumSeatCount={minimumSeatCount}
                />
            )}
            {isPaid ? (
                <TeamOrganizationBlock
                    adminString={adminString}
                    collaboratorString={collaboratorString}
                    annualPrice={annualPrice}
                    billingCycle={billingCycle}
                    monthlyPrice={monthlyPrice}
                    nextPaymentTimestamp={nextPaymentTimestamp}
                    assignedSeats={assignedSeats}
                    unassignedSeats={unassignedSeats}
                    seatCount={seatCount}
                    plan={plan}
                    openModal={openModal}
                    visitCustomerPortal={visitCustomerPortal}
                />
            ) : (
                <FreeOrganizationBlock
                    adminString={adminString}
                    collaboratorString={collaboratorString}
                    assignedSeats={assignedSeats}
                    unassignedSeats={unassignedSeats}
                    seatCount={seatCount}
                    plan={plan}
                    openModal={openModal}
                />
            )}
        </>
    )
}

AdminViewManageSubscriptionModal.propTypes = {
    organizationName: PropTypes.string.isRequired,
    currentSeatCount: PropTypes.number.isRequired,
    assignedSeats: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDowngrade: PropTypes.func.isRequired,
}

AdminViewSubscriptionBlock.propTypes = {
    organization: PropTypes.object.isRequired,
    admins: PropTypes.number.isRequired,
    collaborators: PropTypes.number.isRequired,
    monthlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    changeSeatCount: PropTypes.func.isRequired,
    downgrade: PropTypes.func.isRequired,
    upgrade: PropTypes.func.isRequired,
    visitCustomerPortal: PropTypes.func.isRequired,
}

export { AdminViewSubscriptionBlock, AdminViewManageSubscriptionModal, SubscriptionSeatsWarning, RoundButton }
