// ---------------------------------------------------------------------------------------------------------------------
// Projects
// ---------------------------------------------------------------------------------------------------------------------
const participantKeys = [
    'createdAt',
    'createdBy',
    'lastUpdatedAt',
    'lastUpdatedBy',
    'id',
    'firstName',
    'lastName',
    'timezone',
    'avatarUrl',
    'email',
]
const suspendedParticipantKeys = [
    'createdAt',
    'createdBy',
    'lastUpdatedAt',
    'lastUpdatedBy',
    'id',
    'firstName',
    'lastName',
    'avatarUrl',
]

export { participantKeys, suspendedParticipantKeys }
