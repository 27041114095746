import SampleIds from './sample-ids.js'

const presence = canvas => ({ canvas, lastPresencePlace: [0, 0], lastPresenceTimestamp: 0 })
const vanderbiltPresences = {
    [SampleIds.jason]: presence(SampleIds.vanderbiltPage2),
    [SampleIds.jeff]: presence(SampleIds.vanderbiltPage2),
    [SampleIds.sam]: presence(SampleIds.vanderbiltPage2),
}
const thePreservePresences = {
    [SampleIds.jason]: presence(SampleIds.thePreservePdfCanvasPage1),
    [SampleIds.jeff]: presence(SampleIds.thePreservePdfCanvasPage1),
    [SampleIds.sam]: presence(SampleIds.thePreservePdfCanvasPage1),
}
const ridgedalePresences = {
    [SampleIds.rafal]: presence(SampleIds.ridgedaleAveCanvas1),
    [SampleIds.tom]: presence(SampleIds.ridgedaleAveCanvas1),
    [SampleIds.jeff]: presence(SampleIds.ridgedaleAveCanvas1),
    [SampleIds.greg]: presence(SampleIds.ridgedaleAveCanvas1),
}

export { ridgedalePresences, thePreservePresences, vanderbiltPresences }
