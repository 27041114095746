import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, Checkbox, Flex, FlexColumn, FlexRow, Text, TextInput } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { SelectAdminRole } from './pages/SharedComponents.js'
import { userRoleOptions } from './RegistrationCreateAcccount.js'

const ModalContainer = ({ children, ...restProps }) => {
    return (
        <Box
            onClick={e => {
                e.preventDefault()
                e.stopPropagation() // Needed to not bubble click action so overlay click doesn't trigger
            }}
            data-cy="modal"
            style={{ maxHeight: '100%' }}
            {...restProps}
        >
            {children}
        </Box>
    )
}

const StyledModalContainer = styled(ModalContainer, {
    bg: '$neutral09Alpha',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000025',
    zIndex: 1000,
    position: 'relative',

    variants: {
        type: {
            admin: {
                bg: '$neutral10',
                boxShadow: '0px 40px 40px #00000015',
                backdropFilter: 'none',
            },
            wide: {
                bg: '$neutral10',
                width: '560px',
                border: '1px solid $neutral08',
                backdropFilter: 'none',
            },
        },
    },
})

const StyledInnerBox = styled(Box, {
    padding: '24px',
    display: 'Flex',
    justifyContent: 'left',
    gap: '20px',
    flexDirection: 'column',
    width: '100%',
})

const StyledModalInput = styled(Box, {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    transitionDuration: '0.4s',
})

const StyledSecondaryLabel = styled(Text, {
    color: '$neutral04',
    fontSize: '12px',
})

const StyledInput = styled(TextInput, {
    width: '100%',
    boxSizing: 'border-box',
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

/*
 * Delete Project modal CSS
 */

const StyledConfirmTextBox = styled(TextInput, {
    mt: '8px',

    input: {
        color: '$red03',
        '&::placeholder': {
            color: '$red02',
        },
        '&:hover': {
            border: '1px solid $red03',
        },
        '&:focus': {
            border: '1px solid $red03',
        },
    },
})

/*
 * Invite workspace members modal CSS
 */

const StyledPrimaryModalLabel = styled(Text, {
    fs: '18px',
    fw: 600,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    padding: '40px 0',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '999',
})

/*
 * Edit workspace name modal
 */

const EditWorkspaceNameModal = ({ onUpdate, onCancel }) => {
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>{'Update workspace name'}</StyledPrimaryModalLabel>
                    <StyledModalInput>
                        <StyledInput placeholder={'Enter workspace name...'} />
                    </StyledModalInput>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: '40px' }} variant="primary" size="lg" onClick={onUpdate}>
                        Update
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Edit account password modal
 */

const EditPasswordModal = ({
    onUpdate,
    onCancel,
    primaryLabel,
    inputSecondaryLabelOne,
    inputSecondaryLabelTwo,
    inputSecondaryLabelThree,
    inputPlaceholderLabelOne,
    inputPlaceholderLabelTwo,
    inputPlaceholderLabelThree,
}) => {
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledModalInput>
                        <StyledSecondaryLabel>{inputSecondaryLabelOne}</StyledSecondaryLabel>
                        <Flex css={{ height: 40 }}>
                            <StyledInput placeholder={inputPlaceholderLabelOne} />
                        </Flex>
                    </StyledModalInput>
                    <StyledModalInput>
                        <StyledSecondaryLabel>{inputSecondaryLabelTwo}</StyledSecondaryLabel>
                        <Flex css={{ height: 40 }}>
                            <StyledInput placeholder={inputPlaceholderLabelTwo} />
                        </Flex>
                    </StyledModalInput>
                    <StyledModalInput>
                        <StyledSecondaryLabel>{inputSecondaryLabelThree}</StyledSecondaryLabel>
                        <Flex css={{ height: 40 }}>
                            <StyledInput placeholder={inputPlaceholderLabelThree} />
                        </Flex>
                    </StyledModalInput>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: '40px' }} variant="primary" size="lg" onClick={onUpdate}>
                        Update
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Edit account name model
 */

const EditUserNameModal = ({ user, onUpdate, onCancel }) => {
    const _onUpdate = () => onUpdate(firstName, lastName)

    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)

    const missingFirstOrLastName = firstName.length === 0 || lastName.length === 0
    const neitherNameChanged = firstName === user.firstName && lastName === user.lastName
    const disabled = missingFirstOrLastName || neitherNameChanged

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>{'Update Name'}</StyledPrimaryModalLabel>
                    <StyledModalInput>
                        <StyledSecondaryLabel>{'First Name'}</StyledSecondaryLabel>
                        <StyledInput placeholder={firstName} initialValue={firstName} onChange={setFirstName} />
                    </StyledModalInput>
                    <StyledModalInput>
                        <StyledSecondaryLabel>{'Last Name'}</StyledSecondaryLabel>
                        <StyledInput placeholder={lastName} initialValue={lastName} onChange={setLastName} />
                    </StyledModalInput>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: 40 }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: 40 }} disabled={disabled} variant="primary" size="lg" onClick={_onUpdate}>
                        Update
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Delete project modal
 */

const DeleteProjectModal = ({ project, onCancel, onSubmit, primaryLabel, secondaryLabel }) => {
    const [enabled, setEnabled] = useState(false)

    // enable the delete button only if the typed text matches the project name
    const onChange = inputValue => setEnabled(inputValue === project.name)

    const css = { height: '40px' }
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ pb: '16px', lh: '1.4' }}>
                        Are you sure you want to delete this project?
                    </StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>
                        Deleting this project means that all data will be lost forever. It CAN’T be recovered.
                        Alternative you can “Archive” the project.
                    </StyledSecondaryModalLabel>
                    <StyledSecondaryModalLabel css={{ display: 'flex', gap: '6px', marginTop: '20px' }}>
                        Confirm by typing <Box css={{ color: '$red03' }}>{project.name}</Box> below.
                    </StyledSecondaryModalLabel>

                    <StyledConfirmTextBox
                        data-cy="input-delete-project"
                        css={{
                            input: {
                                color: '$red03',
                                caretColor: '$red03',
                                '&:hover': {
                                    border: '1px solid $red03',
                                },
                                '&:focus': {
                                    border: '1px solid $red03',
                                },
                            },
                        }}
                        placeholder={project.name}
                        onChange={onChange}
                        noClear
                    />
                </Box>

                <StyledModalActions>
                    <Button css={css} variant="secondary" size="lg" onClick={onCancel} data-cy="modal-cancel">
                        Cancel
                    </Button>
                    <Button
                        data-cy="button-delete-project"
                        css={css}
                        variant="destructive"
                        disabled={!enabled}
                        size="lg"
                        onClick={onSubmit}
                    >
                        Delete Project
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Destructive modal
 */

const DestructiveModal = ({ onDelete, onCancel, primaryLabel, secondaryLabel, destructiveButtonLabel, css = {} }) => {
    const css1 = { height: '40px' }
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer css={css}>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '16px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>{secondaryLabel}</StyledSecondaryModalLabel>
                </Box>
                <StyledModalActions>
                    <Button css={css1} variant="secondary" size="lg" onClick={onCancel} data-cy="modal-cancel">
                        Cancel
                    </Button>
                    <Button css={css1} variant="destructive" size="lg" onClick={onDelete} data-cy="modal-confirm">
                        {destructiveButtonLabel}
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Delete a Collaboration with an extra checkbox to confirm you really mean it
 */

const DeleteCollaborationModal = ({ onDelete, onCancel }) => {
    const [confirmed, setConfirmed] = useState()
    const primaryLabel = 'Are you sure you want to delete this pin?'
    const secondaryLabel = `
        Deleting this pin will also delete all associated content.
        This includes comments, photos, documents, history, etc. This cannot be undone.
    `
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '16px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ paddingBottom: '24px' }}>
                        {secondaryLabel}
                    </StyledSecondaryModalLabel>
                    <FlexRow css={{ gap: '12px', alignItems: 'center' }}>
                        <Checkbox
                            variant="destructive"
                            size="lg"
                            defaultChecked={false}
                            checked={confirmed}
                            onCheckedChange={setConfirmed}
                        />
                        <StyledSecondaryModalLabel>Confirm you would like to delete this pin</StyledSecondaryModalLabel>
                    </FlexRow>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="destructive" size="lg" onClick={onDelete} disabled={!confirmed}>
                        Delete Pin
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

DeleteCollaborationModal.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

/*
 * Proceed modal: user has to decide to take the action
 */
const ProceedModal = ({ onAction, onCancel, primaryLabel, secondaryLabel, buttonLabel, tertiaryLabel, css = {} }) => {
    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer css={css}>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ pb: '16px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ pb: 0 }}>{secondaryLabel}</StyledSecondaryModalLabel>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: '40px' }} variant="primary" size="lg" onClick={onAction}>
                        {buttonLabel}
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

ProceedModal.propTypes = {
    primaryLabel: PropTypes.string.isRequired,
    secondaryLabel: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    css: PropTypes.object,
}

/*
 * Alert modal
 */
const AlertModal = ({ onAction, primaryLabel, secondaryLabel, buttonLabel, css = {} }) => {
    return (
        <StyledOverlay>
            <StyledModalContainer css={css}>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ pb: '16px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ pb: 0 }}>{secondaryLabel}</StyledSecondaryModalLabel>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="primary" size="lg" onClick={onAction}>
                        {buttonLabel}
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

/*
 * Edit workspace name modal
 */

const EditUserRoleModal = ({ user, onUpdate, onCancel }) => {
    const _onSubmit = e => {
        e.preventDefault()
        onUpdate(role)
    }
    const [role, setRole] = useState(user.role)
    const disabled = role === user.role

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>Update Job Title</StyledPrimaryModalLabel>
                    <FlexRow css={{ gap: '4px', height: '40px' }}>
                        <SelectAdminRole options={userRoleOptions} value={role} onValueChange={setRole} />
                    </FlexRow>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        css={{ height: '40px' }}
                        disabled={disabled}
                        type="submit"
                        variant="primary"
                        size="lg"
                        onClick={_onSubmit}
                    >
                        Update
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const LeaveOrganizationModal = ({ user, onSubmit, onCancel }) => {
    const _onSubmit = e => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>Are you sure you want to leave this Organization?</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>
                        You will no longer have access of any kind, but your photos, documents and comments will be
                        preserved. You can be re-invited by a team member at any time.
                    </StyledSecondaryModalLabel>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: '40px' }} type="submit" variant="destructive" size="lg" onClick={_onSubmit}>
                        Leave Organization
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const SuspendMemberModal = ({ user, submitButtonCopy = 'Suspend Member', onSubmit, onCancel }) => {
    const _onSubmit = e => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="admin">
                <StyledInnerBox>
                    <StyledPrimaryModalLabel>
                        Are you sure you want to suspend {user.firstName} {user.lastName} from your Organization?
                    </StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>
                        They will no longer have access of any kind, but all their photos, documents and comments will
                        be preserved. You can always re-invite them later.
                    </StyledSecondaryModalLabel>
                </StyledInnerBox>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button css={{ height: '40px' }} type="submit" variant="destructive" size="lg" onClick={_onSubmit}>
                        {submitButtonCopy}
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const CanvasEditorDeleteModalEmpty = ({ onCancel, onDelete }) => (
    <DestructiveModal
        onDelete={onDelete}
        onCancel={onCancel}
        primaryLabel="Are you sure you want to delete this PDF?"
        secondaryLabel="Deleting this PDF sheet means it will no longer exist."
        destructiveButtonLabel="Delete PDF"
    />
)

const CanvasEditorDeleteModalWithPins = ({ canvasName, onCancel, onDelete }) => {
    const [enabled, setEnabled] = useState(false)

    // enable the delete button only if the typed text matches the canvasName name
    const handleInputChange = value => setEnabled(value.trim() === canvasName)

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel
                        css={{ pb: '16px', lh: '1.4' }}
                    >{`PDF sheet ${canvasName} has pins attached to it. Are you sure you want to delete this sheet?`}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>
                        Deleting this sheet means that all pins and associated media will be lost forever, this can’t be
                        undone. it will no longer be available.
                    </StyledSecondaryModalLabel>
                    <br />
                    <StyledSecondaryModalLabel css={{ display: 'flex', gap: '6px', marginBottom: '24px' }}>
                        Confirm by typing <Box css={{ color: '$red03' }}>{canvasName}</Box> below.
                    </StyledSecondaryModalLabel>
                    <StyledConfirmTextBox
                        css={{
                            input: {
                                color: '$red03',
                                caretColor: '$red03',
                                '&:hover': {
                                    border: '1px solid $red03',
                                    background: '$neutral09',
                                },
                                '&:focus': {
                                    border: '1px solid $red03',
                                    background: '$neutral09',
                                    color: '$red03',
                                },
                            },
                        }}
                        placeholder={canvasName}
                        onChange={handleInputChange}
                        noClear
                    />
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        css={{ height: '40px' }}
                        variant="destructive"
                        disabled={!enabled}
                        size="lg"
                        onClick={onDelete}
                    >
                        Delete PDF Sheet
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const CanvasEditorDeleteModal = { Empty: CanvasEditorDeleteModalEmpty, WithPins: CanvasEditorDeleteModalWithPins }

export {
    EditWorkspaceNameModal,
    EditPasswordModal,
    EditUserNameModal,
    DeleteProjectModal,
    DeleteCollaborationModal,
    DestructiveModal,
    AlertModal,
    EditUserRoleModal,
    ProceedModal,
    CanvasEditorDeleteModal,
    StyledOverlay,
    StyledModalContainer,
    StyledPrimaryModalLabel,
    StyledSecondaryModalLabel,
    StyledModalActions,
    LeaveOrganizationModal,
    SuspendMemberModal,
}
