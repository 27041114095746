import { pick } from '@range.io/functional'
import SampleIds from './sample-ids.js'

const sampleUpdates = {
    [SampleIds.vanderbiltCollaboration1Update1]: {
        createdAt: 1664270989308,
        lastUpdatedAt: 1664270989308,
        createdBy: SampleIds.sam,
        lastUpdatedBy: SampleIds.sam,
        parent: SampleIds.vanderbiltCollaboration1,
        parentType: 'Collaboration',
        field: 'name',
        oldValue: '',
        newValue: 'Repair water damage',

        collaborationId: SampleIds.vanderbiltCollaboration1,
        featureId: SampleIds.vanderbiltFeature1,
        geometryId: SampleIds.vanderbiltGeometry1,
        canvasId: SampleIds.vanderbiltPage2,
        projectId: SampleIds.vanderbiltProject,
        organizationId: SampleIds.range,
    },
}
const vanderbiltUpdates = pick([SampleIds.vanderbiltCollaboration1Update1], sampleUpdates)

export { vanderbiltUpdates }
