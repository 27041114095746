import React, { useState } from 'react'

import { Button, Flex, FlexRow, Icon, Select, Text, TextInput } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

// TODO: Consider creating input with icon
const StyledSearchInputWrapper = styled('div', {
    position: 'relative',
    marginRight: 'auto',
    width: 336,
    height: 40,
    padding: 0,

    input: {
        paddingLeft: '30px',
        fontWeight: 500,
        '&:hover': { cursor: 'pointer' },
    },
})

const StyledSearchIcon = styled(Icon, {
    position: 'absolute',
    zIndex: '1',
    top: 8,
    left: 6,
    pointerEvents: 'none',
})

const ParticipantsDropdownFilter = ({ filterOptions, value, onValueChange }) => {
    return (
        <Flex direction="row" css={{ alignItems: 'center', gap: '8px' }}>
            <Text css={{ color: '$neutral04', fs: '14px', fw: '500' }}>Showing</Text>
            <Select
                data-cy="participants-filter"
                options={filterOptions}
                value={value}
                onValueChange={onValueChange}
                capitalize
                css={{
                    lineHeight: '30px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    height: 40,
                    minWidth: '164px',
                    boxSizing: 'border-box',
                }}
            />
        </Flex>
    )
}

const SearchInput = ({ placeholder = 'Search by name or email...', value, width = 336, onChange }) => {
    return (
        <Flex style={{ width, height: 40 }}>
            <StyledSearchInputWrapper>
                <StyledSearchIcon iconSize="24" css={{ color: '$neutral05' }} name="search" />
                <TextInput placeholder={placeholder} onChange={onChange} value={value} />
            </StyledSearchInputWrapper>
        </Flex>
    )
}

const StyledRolesAndPermissionModal = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '$neutralOverlay',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
})

/*
 * Show https://www.range.io/permissions in an iFrame
 */
const ViewRolesAndPermissions = ({ handleCloseModal }) => {
    const topRight = { position: 'absolute', right: '24px', top: '24px' }
    return (
        <StyledRolesAndPermissionModal>
            <Button size="lg" onClick={handleCloseModal} variant="primary" css={topRight}>
                Close
            </Button>
            <iframe
                src="https://www.range.io/permissions"
                style={{ width: '100%', height: '100%', border: 'none', overflow: 'hidden' }}
                title="Permissions"
            />
        </StyledRolesAndPermissionModal>
    )
}

/**
 * TableTools is a component that renders a set of tools for managing
 * and filtering participants in a table. It includes a search input, a dropdown filter,
 * and an optional button for managing participants.
 *
 * @param {boolean} canManageParticipants - Determines if the participants management button should be displayed.
 * @param {Array} dropdownFilterOptions - An array of options for the dropdown filter.
 * @param {String} dropdownFilterValue - The current value of the dropdown filter.
 * @param {String} searchValue - The current value of the search input.
 * @param {String} membersButtonDataCy - The `data-cy` attribute value for the members management button, used for testing.
 * @param {String} membersButtonText - The text displayed inside the members management button.
 * @param {Function} onDropdownFilterValueChange - Callback function to handle changes in the dropdown filter value.
 * @param {Function} onMembersButtonClick - Callback function to handle clicks on the members management button.
 * @param {Function} setSearchValue - Callback function to handle changes in the search input value.
 *
 * @returns {JSX.Element} The rendered component.
 */
const TableTools = ({
    canManageParticipants,
    dropdownFilterOptions,
    dropdownFilterValue,
    inputWidth,
    inputPlaceholder,
    searchValue,
    membersButtonDataCy,
    membersButtonText,
    onDropdownFilterValueChange,
    onMembersButtonClick,
    setSearchValue,
    css,
    showRolesButton = true,
}) => {
    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => setModalOpen(true)
    const handleCloseModal = () => setModalOpen(false)

    return (
        <Flex direction="row" justify="between" css={css}>
            <FlexRow css={{ gap: 16, justifyContent: 'start' }}>
                <SearchInput
                    value={searchValue}
                    onChange={setSearchValue}
                    width={inputWidth}
                    placeholder={inputPlaceholder}
                />
                {showRolesButton && (
                    <Button variant="secondary" size="lg" onClick={handleOpenModal}>
                        <Icon name="user" iconSize="14" />
                        View Roles & Permissions
                    </Button>
                )}
            </FlexRow>

            <Flex css={{ gap: '16px' }}>
                <ParticipantsDropdownFilter
                    filterOptions={dropdownFilterOptions}
                    value={dropdownFilterValue}
                    onValueChange={onDropdownFilterValueChange}
                />
                {!!canManageParticipants && (
                    <Button
                        data-cy={membersButtonDataCy}
                        css={{ width: 170 }}
                        variant="primary"
                        size="lg"
                        onClick={onMembersButtonClick}
                    >
                        <Icon iconSize="18" name="addUser" />
                        <Text>{membersButtonText}</Text>
                    </Button>
                )}
            </Flex>

            {isModalOpen && <ViewRolesAndPermissions handleCloseModal={handleCloseModal} />}
        </Flex>
    )
}

export { SearchInput, TableTools }
