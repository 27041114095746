import { Timestamp } from '@firebase/firestore'

/*
 * Convert a JS Date object to a Firestore Timestamp; date can be undefined (for optional timestamps, like dueDate)
 * @sig dateToTimestamp Date -> Timestamp|undefined
 */
const dateToTimestamp = date => (date ? Timestamp.fromDate(date) : undefined)

/*
 * Convert a JS millisecond (number) to a Firestore Timestamp; millis can be undefined (for optional timestamps)
 * @sig millisToTimestamp Number -> Timestamp|undefined
 */
const millisToTimestamp = millis => (millis ? Timestamp.fromMillis(millis) : undefined)

/*
 * A "timestamp" returned from an onCall is a simple JS object with second and nanosecond fields, but no toDate function
 * @sig secondsAndNanosecondsToDate :: { seconds: Number, nanoseconds: Number } -> Date
 */
const secondsAndNanosecondsToDate = timestamp => new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000)
/*
 * Convert a Firestore Timestamp to a JS Date; timestamp can be undefined (for optional timestamps, like dueDate)
 * @sig timestampToDate Timestamp | {seconds: Number, nanoseconds: Number} | undefined -> Date|undefined
 */
const timestampToDate = timestamp => {
    if (timestamp?.toDate) return timestamp.toDate()
    if (typeof timestamp?.seconds === 'number') return secondsAndNanosecondsToDate(timestamp)
    return undefined // guess there was no timestamp sent at all
}

/*
 * Convert a Firestore Timestamp to a JS millis (number); timestamp can be undefined (for optional timestamps)
 * @sig timestampToMillis Timestamp -> Number|undefined
 */
const timestampToMillis = timestamp => (timestamp?.toMillis ? timestamp.toMillis() : undefined)

/*
 * Convert EITHER a JS millis OR a Firestore Timestamp to a Date
 * @sig millisOrTimestampToDate Number|Timestamp -> Date
 */
const millisOrTimestampToDate = ts => (typeof ts === 'number' ? new Date(ts) : timestampToDate(ts))

export { dateToTimestamp, timestampToDate, millisToTimestamp, timestampToMillis, millisOrTimestampToDate }
