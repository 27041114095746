/* ---------------------------------------------------------------------------------------------------------------------
 * MockCollectionReference
 * ---------------------------------------------------------------------------------------------------------------------
 * https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 *
 * Note: In Firestore, CollectionReference extends Query!
 *
 * Fields
 *
 *  >>> id        String            The collection's identifier; last element of path
 *      parent    DocumentReference A reference to the containing DocumentReference if this is a subcollection. If this isn't a subcollection, the reference is null.
 *  >>> path      String            A string representing the path of the referenced collection (relative to the root of the database).
 *  >>> type      String            The type of this Firestore reference; always 'collection'
 *
 * No relevant functions, either static or not
 * ------------------------------------------------------------------------------------------------------------------ */
import { tagged } from '@range.io/functional'

const MockCollectionReference = tagged('MockCollectionReference', {
    type: /collection/,
    id: 'String',
    path: 'String',
})

export default MockCollectionReference
