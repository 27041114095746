import { CanvasSource } from '@range.io/basic-types'
import { pickAWord } from '@range.io/functional'
import { httpsCallable } from 'firebase/functions'
import { getUtmAttributionParams } from '../../segment/segment.js'
import { functions } from '../configure-environment/config-local.js'

// prettier-ignore
const handlers = {
    acceptInvitation                      : true,
    addOrganizationParticipant            : true,
    addProjectParticipant                 : true,
    changeOrganizationRole                : true,
    changeSeatCount                       : true,
    createCollaboration                   : true,
    createComment                         : true,
    createOrganization                    : true,
    createProject                         : true,
    createUpload                          : true,
    deleteCanvas                          : true,
    deleteCollaboration                   : true,
    deleteComment                         : true,
    deleteProject                         : true,
    deleteUpload                          : true,
    generateIntercomUserHash              : true,
    getAllDataForProject                  : true,
    getProjectInfo                        : true,
    inviteToOrganization                  : true,
    reInviteOrganizationParticipant       : true,
    removeInvitation                      : true,
    removeOrganizationParticipant         : true,
    removeProjectParticipant              : true,
    removeSelfFromOrganizationParticipants: true,
    resendInvitation                      : true,
    updateCategories                      : true,
    updateCollaboration                   : true,
    updateComment                         : true,
    updateKnockUserToken                  : true,
    updateStatusNames                     : true,
    updateUpload                          : true,
    upgradeFromFreePlan                   : true,
    visitCustomerPortal                   : true,
}

/*
 * Wrap a Firebase Function call (httpsCallable) and return the result
 * @sig httpsCall :: (String, {k:v}) -> Promise {k:v}
 */
const httpsCall = async (functionName, inputData) => {
    const useGenericOnCall = !!handlers[functionName]
    const callable = useGenericOnCall
        ? httpsCallable(functions, 'genericOnCall')
        : httpsCallable(functions, functionName)

    // (mostly for Cypress testing, where the functions are only written on an as-needed basis)
    if (!callable) throw new Error(`Internal error: didn't find function '${functionName}'`)

    const { data } = await callable(useGenericOnCall ? { functionName, ...inputData } : inputData)
    return data
}

/*
 * Call to remote createCollaboration; returns nothing. Don't create Canvas, Geometry, Feature or Collaboration
 * All parameters are required except, that statusId must be set only for tasks
 */
const createCollaboration = async ({
    projectId,
    canvasId,
    geometryId,
    featureId,
    collaborationId,
    coordinates,
    statusId,
    name = '',
    description,
    assigneeId,
    dueDateTimestamp,
    tags,
    followers,
    categoryId,
    uploads,
}) => {
    // following values can't be null, pass them if they have defined value, otherwise skip
    const dataWithDefinedValues = {
        tags,
        followers,
    }

    // We have to go through each argument and skip passing it down if it's undefined.
    // Otherwise it gets turned into null.
    const notNullData = {}
    Object.keys(dataWithDefinedValues).forEach(prop => {
        if (dataWithDefinedValues[prop] !== undefined) notNullData[prop] = dataWithDefinedValues[prop]
    })

    return await httpsCall('createCollaboration', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        canvasId,
        geometryId,
        featureId,
        collaborationId,
        coordinates,
        statusId,
        name,
        description,
        assigneeId,
        dueDateTimestamp,
        categoryId,
        ...notNullData,
    })
}
/*
 * Call to remote createComment; returns nothing.
 */
const createComment = async ({ projectId, commentId, parentType, parentId, text, isNote }) =>
    await httpsCall('createComment', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        commentId,
        parentType,
        parentId,
        text,
        isNote,
    })

/*
 * Call to remote createOrganization; returns id of new Organization
 * @sig createOrganization :: (String, String) -> Id
 */
const createOrganization = async (name, industry, organizationSize) => {
    const utmAttribution = getUtmAttributionParams()
    const { organizationId } = await httpsCall('createOrganization', {
        structuredLogId: doubleRandomCorrelationId(),
        name,
        industry,
        organizationSize,
        utmAttribution,
    })
    return organizationId
}

/*
 * Call to remote createOrganization; returns id of new Organization
 * @sig createOrganization :: (String, String) -> Id
 */
const createProject = async ({
    organizationId,
    projectId,
    projectType,
    name,
    center,
    address,
    canvasOrder,
    description,
    tagSource,
    statusSource,
    categorySource,
    canvases,
    canvasSources,
}) => {
    await httpsCall('createProject', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        projectId,
        projectType,
        name,
        center,
        address,
        canvasOrder,
        description,
        tagSource,
        statusSource,
        categorySource,
        canvases,
        canvasSources: canvasSources.map(CanvasSource.toFirebase),
    })
    return projectId
}

/*
 * Call to remote createUpload; returns nothing.
 */
const createUpload = async ({
    // required:
    projectId,
    collaborationId,
    uploadId,
    fileType,
    fileSize,
    imageHeightToWidth,
    // not required:
    correlationId,
    name,
    description,
    tags,
    annotations,
}) => {
    await httpsCall('createUpload', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        collaborationId,
        uploadId,
        fileType,
        fileSize,
        imageHeightToWidth,
        correlationId,
        name,
        description,
        tags,
        annotations,
    })
}

/*
 * Call to remote deleteCanvas
 * @sig deleteCanvas :: (Id, Id) -> Promise void
 */
const deleteCanvas = async (projectId, canvasId) =>
    await httpsCall('deleteCanvas', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        canvasId,
    })

/*
 * Call to remote deleteCollaboration
 * @sig deleteCollaboration :: (Id, Id) -> Promise void
 */
const deleteCollaboration = async (projectId, collaborationId) =>
    await httpsCall('deleteCollaboration', { structuredLogId: doubleRandomCorrelationId(), projectId, collaborationId })

/*
 * Call to remote deleteComment
 * @sig deleteComment :: (Id, Id) -> Promise void
 */
const deleteComment = async (projectId, commentId) =>
    await httpsCall('deleteComment', { structuredLogId: doubleRandomCorrelationId(), projectId, commentId })

/*
 * Call to remote deleteProject
 * @sig deleteProject :: (Id) -> Promise void
 */
const deleteProject = async projectId =>
    await httpsCall('deleteProject', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
    })

/*
 * Call to remote deleteUpload
 * @sig deleteUpload :: (Id, Id) -> Promise void
 */
const deleteUpload = async (projectId, uploadId) =>
    await httpsCall('deleteUpload', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        uploadId,
    })

/*
 * Call to remote inviteToOrganization
 * @sig inviteToOrganization :: (Id, Invitation) -> Promise void
 */
const inviteToOrganization = async (organizationId, invitation) => {
    const { inviteeEmail, organizationRole, projectIds } = invitation
    await httpsCall('inviteToOrganization', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        inviteeEmail,
        organizationRole,
        projectIds,
    })
}

/*
 * Call to remote resendInvitation
 * @sig resendInvitation :: (Id, Id) -> Promise void
 */
const resendInvitation = async (organizationId, invitationId) =>
    await httpsCall('resendInvitation', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        invitationId,
    })

/*
 * Call to remote acceptInvitation
 * @sig acceptInvitation :: Id -> Promise void
 */
const acceptInvitation = async invitationId => {
    const utmAttribution = getUtmAttributionParams()
    await httpsCall('acceptInvitation', {
        structuredLogId: doubleRandomCorrelationId(),
        invitationId,
        utmAttribution,
    })
}

/*
 * Call to remote removeInvitation
 * @sig removeInvitation :: (Id, Id) -> Promise void
 */
const removeInvitation = async (organizationId, invitationId) =>
    await httpsCall('removeInvitation', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        invitationId,
    })

/*
 * Call to remote addProjectParticipant; users/{userId} and projects/{projectId} must exist
 * @sig addProjectParticipant :: { [Id], Id } -> Promise void
 */
const addProjectParticipant = async (projectIds, userId) =>
    await httpsCall('addProjectParticipant', {
        structuredLogId: doubleRandomCorrelationId(),
        projectIds,
        userId,
    })

/*
 * Call to remote removeProjectParticipant; users/{userId} and projects/{projectId} must exist
 * @sig removeProjectParticipant :: { [Id], Id } -> Promise void
 */
const removeProjectParticipant = async (projectIds, userId) =>
    await httpsCall('removeProjectParticipant', {
        structuredLogId: doubleRandomCorrelationId(),
        projectIds,
        userId,
    })

/*
 * Call to remote removeOrganizationParticipant; users/{userId} and organization/{organizationId} must exist
 * @sig removeOrganizationParticipant :: { Id, Id } -> Promise void
 */
const removeOrganizationParticipant = async (organizationId, userId) =>
    await httpsCall('removeOrganizationParticipant', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        userId,
    })

/*
 * Call to remote removeSelfFromOrganizationParticipants; users/{userId} and organization/{organizationId} must exist
 * @sig removeSelfFromOrganizationParticipants :: { Id, Id } -> Promise void
 */
const removeSelfFromOrganizationParticipants = async (organizationId, userId) =>
    await httpsCall('removeSelfFromOrganizationParticipants', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        userId,
    })

/*
 * Call to remote reInviteParticipant; organization/{organizationId} and changes must exist
 * @sig reInviteParticipant :: { Id, object } -> Promise void
 */
const reInviteOrganizationParticipant = async (organizationId, changes) =>
    await httpsCall('reInviteOrganizationParticipant', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        changes,
    })

/*
 * Call to remote changeOrganizationRole
 * @sig changeOrganizationRole :: (Id, Id, Admin|Collaborator) -> Promise void
 */
const changeOrganizationRole = async (organizationId, userId, organizationRole, projectIds) =>
    await httpsCall('changeOrganizationRole', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        userId,
        organizationRole,
        projectIds,
    })

/*
 * Call to remote updateCollaboration, returns {projectId, updateIds}.
 */
const updateCollaboration = async ({ projectId, collaborationId, ...changes }) => {
    // We have to go through each argument and skip passing it down if it's undefined.
    // Otherwise it gets turned into null.
    const changesForFunction = {}
    Object.keys(changes).forEach(change => {
        if (changes[change] !== undefined) changesForFunction[change] = changes[change]
    })

    return await httpsCall('updateCollaboration', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        collaborationId,
        ...changesForFunction,
    })
}

/*
 * Call to remote updateComment
 * @sig updateComment = ({ projectId: Id, commentId: Id, text: String, completedTimestamp: Int }) -> Promise null
 */
const updateComment = async ({ projectId, commentId, text, completedTimestamp }) =>
    await httpsCall('updateComment', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        commentId,
        text,
        completedTimestamp,
    })

/*
 * Call to remote updateUpload
 * Note: we only change 1 field at a time, so changes will only have one of annotations, description, name, tags
 * @sig updateUpload = { projectId: Id, uploadId: Id, changes: Changes } } -> Promise null
 *  changes = { annotations: JSONString } | { description: String | { name: String } | { tags: [IdAndName] }
 *  IdAndName = { id: Id, name: string }
 */
const updateUpload = async ({ projectId, uploadId, changes }) =>
    await httpsCall('updateUpload', {
        structuredLogId: doubleRandomCorrelationId(),
        projectId,
        uploadId,
        ...changes,
    })

const updateKnockUserToken = async () =>
    await httpsCall('updateKnockUserToken', { structuredLogId: doubleRandomCorrelationId() })

const updateCategories = async ({ projectId, categories, deleted }) =>
    await httpsCall('updateCategories', { projectId, categories, deleted })

const updateStatusNames = async ({ projectId, statusNames, deleted }) =>
    await httpsCall('updateStatusNames', { projectId, statusNames, deleted })

const getAllDataForProject = async ({ projectId, since }) =>
    await httpsCall('getAllDataForProject', { structuredLogId: doubleRandomCorrelationId(), projectId, since })

const getProjectInfo = async ({ projectIds }) =>
    await httpsCall('getProjectInfo', { structuredLogId: doubleRandomCorrelationId(), projectIds })

const generateIntercomUserHash = async () => await httpsCall('generateIntercomUserHash', {})

const upgradeFromFreePlan = async (organizationId, billingCycle, seatCount) =>
    await httpsCall('upgradeFromFreePlan', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
        billingCycle,
        seatCount,
    })

const visitCustomerPortal = async organizationId =>
    await httpsCall('visitCustomerPortal', {
        structuredLogId: doubleRandomCorrelationId(),
        organizationId,
    })

const changeSeatCount = async (organizationId, seatCount) =>
    await httpsCall('changeSeatCount', { organizationId, seatCount })

const doubleRandomCorrelationId = (primary = pickAWord(), secondary = pickAWord()) => `web:${primary}:${secondary}`

export {
    acceptInvitation,
    addProjectParticipant,
    changeOrganizationRole,
    changeSeatCount,
    createCollaboration,
    createComment,
    createOrganization,
    createProject,
    createUpload,
    deleteCanvas,
    deleteCollaboration,
    deleteComment,
    deleteProject,
    deleteUpload,
    generateIntercomUserHash,
    getAllDataForProject,
    getProjectInfo,
    inviteToOrganization,
    reInviteOrganizationParticipant,
    removeInvitation,
    removeOrganizationParticipant,
    removeProjectParticipant,
    removeSelfFromOrganizationParticipants,
    resendInvitation,
    updateCategories,
    updateCollaboration,
    updateComment,
    updateKnockUserToken,
    updateStatusNames,
    updateUpload,
    upgradeFromFreePlan,
    visitCustomerPortal,
}
