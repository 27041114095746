/*
 * Admin Page: Projects panel
 */

import { StatusCount } from '@range.io/basic-types/src/core/project-metrics.js'
import { assoc } from '@range.io/functional'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import useLocalStorage from '../components-reusable/hooks/useLocalStorage.js'
import {
    Box,
    Button,
    CardButton,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    Logo,
    ScrollArea,
    Select,
    Text,
    TextInput,
} from '../components-reusable/index.js'
import { getProjectInfo } from '../firebase/commands/https-calls.js'
import { ProjectChangedCommand, ProjectRemovedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import AdminNavigationPanel, { AdminNavigationType } from './AdminNavigationPanel.js'
import AdminOrganizationsPanel from './AdminOrganizationsPanel.js'
import Identifier from './Identifier.js'
import { DeleteProjectModal } from './Modal.js'
import { AdminMenuButtonSupport } from './pages/SharedComponents.js'
import ProjectActionsDropdown from './ProjectActionsDropdown.js'

const projectWrapperCss = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))',
    gap: '32px',
    marginBottom: '32px',
}

const StyledSearchInputWrapper = styled('div', {
    position: 'relative',
    marginRight: 'auto',
    width: 336,
    height: 40,
    padding: 0,

    input: {
        paddingLeft: '30px',
        fontWeight: 500,
        '&:hover': { cursor: 'pointer' },
    },
})

const StyledSearchIcon = styled(Icon, {
    position: 'absolute',
    zIndex: '1',
    top: 8,
    left: 6,
})

const VALID_FOR = 10000
let lastUpdateTimestamp = {}

/*
 * Update the StatusCounts for each project -- but no more often than once every VALID_FOR milliseconds
 */
const updateStatusCounts = async (dispatch, projects) => {
    const projectIds = projects.map(project => project.id)

    const projectsInfo = await getProjectInfo({ projectIds })

    const updateProjectCounts = async project => {
        // skip if we updated "recently"
        const now = Date.now()
        if ((lastUpdateTimestamp[project.id] ?? 0) + VALID_FOR > now) return
        lastUpdateTimestamp = assoc(project.id, now, lastUpdateTimestamp)

        const { statusTaskCounts } = projectsInfo.find(info => info.projectId === project.id)

        // no project info found for this project, skip
        if (!statusTaskCounts) return

        // map each status into StatusCount
        const statusCounts = statusTaskCounts.map(status =>
            StatusCount(status.id, status.name, status.color, status.taskCount)
        )

        // update the state (and trigger a redraw)
        dispatch(ReduxActions.projectMetricsChanged({ projectId: project.id, statusCounts }))
    }

    projects.forEach(updateProjectCounts)
}

/*
 * render a StatusCount object which associates a status and a count
 */
const renderStatusCounts = statusCounts => {
    // Define the desired order
    const order = ['Complete', 'Needs Review', 'Blocked', 'In-progress']

    // Sort statusCounts based on the order array
    const sortedStatusCounts = statusCounts.sort((a, b) => {
        let indexA = order.indexOf(a.name)
        let indexB = order.indexOf(b.name)

        // Handle cases where a status might not be in the order array
        if (indexA === -1) indexA = order.length
        if (indexB === -1) indexB = order.length

        return indexA - indexB
    })

    const renderMetrics = (statusCount, index) => {
        return (
            <Flex
                css={{
                    background: statusCount.color,
                    br: 999,
                    p: '4px 9px',
                    opacity: statusCount.count === 0 && 0.3, // Adjust opacity if count is 0
                }}
                key={index}
            >
                <Text key={statusCount.id} css={{ fs: 12, color: 'white', fw: 600 }}>
                    {statusCount.name} ({statusCount.count})
                </Text>
            </Flex>
        )
    }

    return sortedStatusCounts.map(renderMetrics)
}

/*
 * get total status count
 */
const getTotalStatusCount = statusCounts => {
    return statusCounts.reduce((total, statusCount) => total + statusCount.count, 0)
}

/*
 * Render one of the (large) project buttons in the project list
 */
const renderProjectButton = ({
    canManageProject,
    project,
    projectMetrics,
    onProjectSelected,
    onHideProjectClick,
    onShowProjectClick,
    onDeleteProjectClick,
}) => {
    const onClick = () => onProjectSelected(project)

    const statusCounts = projectMetrics[project.id]?.statusCounts ?? []
    const totalStatusCount = getTotalStatusCount(statusCounts)

    const renderName = () => <Text css={{ mr: 'auto', fw: 700, fs: '16px', fg: '$neutral04' }}>{project.name}</Text>

    const renderDescription = () => (
        <Flex>
            {project.description ? (
                <Text css={{ fs: '14px', fg: '$neutral04', mr: 'auto', pb: '2px' }}>{project.description}</Text>
            ) : (
                <Text css={{ fs: '14px', fg: '$neutral05', mr: 'auto', pb: '2px' }}>No project description...</Text>
            )}
        </Flex>
    )

    const renderAddress = () => (
        <Flex align="top" css={{ mt: 'auto', gap: 8, mb: 10 }}>
            <Text css={{ fs: '12px', fg: '$neutral05', mr: 'auto' }}>{project.address}</Text>
        </Flex>
    )

    const renderIdentifier = () => <Identifier projectIdentifier={project.identifier} css={{ mt: 'auto', mb: 4 }} />

    return (
        <CardButton
            data-cy="project-item"
            key={project.id}
            onClick={onClick}
            css={{
                pt: '14px',
            }}
        >
            <FlexColumn css={{ gap: 6, textAlign: 'left', width: '100%', height: '100%' }}>
                <FlexRow css={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <FlexColumn css={{ width: '100%' }}>
                        <FlexRow css={{ justifyContent: project.identifier ? 'space-between' : 'flex-end' }}>
                            {renderIdentifier()}

                            {canManageProject && (
                                <ProjectActionsDropdown
                                    isArchived={project.isArchived}
                                    canDelete={canManageProject}
                                    onShowProject={() => onShowProjectClick(project)}
                                    onHideProject={() => onHideProjectClick(project)}
                                    onDeleteProject={() => onDeleteProjectClick(project)}
                                />
                            )}
                        </FlexRow>

                        {renderName()}
                    </FlexColumn>
                </FlexRow>
                {renderDescription()}
                <FlexRow css={{ alignItems: 'start', gap: 6, mt: 4 }}>
                    <Flex css={{ minWidth: 16, mt: 0.5, height: 16 }}>
                        <Icon name="pointAddress" iconSize="16" />
                    </Flex>
                    {renderAddress()}
                </FlexRow>
                <Flex css={{ mt: 'auto', mb: 8, width: '100%', background: '$neutral07', height: '1px' }} />
                <FlexColumn css={{ gap: 12, color: '$neutral04', fs: 12, fw: 600 }}>
                    Tasks ({totalStatusCount})
                    <Flex
                        css={{
                            flex: 1, // Allows Flex to expand and fill available space
                            flexDirection: 'row', // Align children in a column
                            justifyContent: 'start', // Align children to the end (bottom)
                            gap: 12,
                            flexWrap: 'wrap',
                        }}
                    >
                        {renderStatusCounts(projectMetrics[project.id]?.statusCounts ?? [])}
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        </CardButton>
    )
}

const SortBySelector = ({ sortByOptions, sortBy, onSortByChange }) => (
    <Flex direction="row" css={{ justifyContent: 'left', alignItems: 'center', mb: '16px', gap: '4px' }}>
        <Text css={{ color: '$neutral05', fs: '14px', fw: '500' }}>Sort by:</Text>
        <Select
            options={sortByOptions}
            value={sortBy}
            onValueChange={onSortByChange}
            capitalize
            data-cy="sort-by"
            css={{
                background: 'transparent',
                border: 'none',
                height: 32,
                '&:hover': {
                    border: 'none',
                },
            }}
        />
    </Flex>
)

/*
 * Render ALL the giant project buttons
 */
const renderProjectButtons = ({
    canManageProject,
    searchPhrase,
    projects,
    projectMetrics,
    onProjectSelected,
    sortByOptions,
    sortBy,
    onSortByChange,
    onHideProjectClick,
    onShowProjectClick,
    onDeleteProjectClick,
}) => {
    const matchesSearch = project => {
        const name = project.name.toLowerCase()
        const address = project.address.toLowerCase()
        return !searchPhrase || name.includes(searchPhrase) || address.includes(searchPhrase)
    }

    const projectButtons = projects.filter(matchesSearch).map(project =>
        renderProjectButton({
            canManageProject,
            project,
            projectMetrics,
            onProjectSelected,
            onHideProjectClick,
            onShowProjectClick,
            onDeleteProjectClick,
        })
    )

    const scrollAreaHeight = 'calc(100vh - var(--gridRow0Height)'

    return (
        <Flex direction="column" css={{ overflow: 'hidden' }}>
            <SortBySelector sortByOptions={sortByOptions} sortBy={sortBy} onSortByChange={onSortByChange} />
            <ScrollArea maxHeight={scrollAreaHeight}>
                <Box css={projectWrapperCss}>{projectButtons}</Box>
            </ScrollArea>
        </Flex>
    )
}

const renderEmptyView = ({ canCreateProject, onNewProjectClick }) => {
    const renderGiantPinIcon = () => (
        <Flex css={{ zIndex: 1, position: 'relative' }}>
            <Flex css={{ w: 14, h: 1, position: 'absolute', filter: 'blur(4px)', background: '$neutral01', mt: 100 }} />
            <Icon name="pin" css={{ color: '$neutral07', width: 64, mb: 12, position: 'relative' }} />
            <Icon name="taskCollaboration" css={{ color: '$neutral04', width: 48, position: 'absolute', pt: 9 }} />
        </Flex>
    )

    const renderWelcomeText = () => (
        <Text css={{ color: '$neutral04', fs: '28px', fw: '700', lh: '24px', mb: '24px', mt: '16px' }}>
            Welcome to Range 😎
        </Text>
    )

    const renderGetStartedTextForAdmin = () => (
        <Text css={{ color: '$neutral05', fs: '18px', fw: '500', lh: '20px', mb: '32px' }}>
            Get started by creating your first project.
        </Text>
    )

    const renderGetStartedText = () => (
        <FlexColumn css={{ gap: 32, alignItems: 'center', maxWidth: 500, textAlign: 'center' }}>
            <Text css={{ color: '$neutral05', fs: '18px', fw: '500' }}>
                Your Organization admins have been notified and will be assigning you projects which will show up here.
            </Text>
            <FlexColumn
                css={{ p: 24, background: '$neutral09', mt: 24, mb: 32, b: '1px solid $neutral07', br: 16, gap: 24 }}
            >
                <FlexColumn css={{ gap: 8 }}>
                    <Text css={{ fs: '24px', fw: '700', color: '$neutral04' }}>Learn about Range</Text>
                    <Text css={{ color: '$neutral05', fs: '16px', fw: '500' }}>
                        Through our short snippet video tutorials, you'll master everything you need to know about
                        Range.
                    </Text>
                </FlexColumn>
                <AdminMenuButtonSupport buttonText="Video Tutorials" variant="emptyProjects" />
            </FlexColumn>
        </FlexColumn>
    )

    const renderNewProjectButton = () => (
        <Button variant="primary" size="lg" onClick={onNewProjectClick}>
            <Icon iconSize="18" name="newProject" />
            <Text>New Project</Text>
        </Button>
    )

    return (
        <FlexColumn css={{ alignItems: 'center', justifyContent: 'center', width: '100%', height: '70vh' }}>
            {renderGiantPinIcon()}
            {renderWelcomeText()}
            {canCreateProject ? renderGetStartedTextForAdmin() : renderGetStartedText()}
            {canCreateProject && renderNewProjectButton()}
        </FlexColumn>
    )
}

const renderNoFilteredProjectsView = ({ projectsFilterValue }) => {
    return (
        <FlexColumn css={{ alignItems: 'center', justifyContent: 'center', width: '100%', height: '70vh' }}>
            <Text css={{ color: '$neutral04', fs: '24px', fw: '700', lh: '24px', mb: '16px', mt: '16px' }}>
                There are no {projectsFilterValue} projects.
            </Text>
            <Text css={{ color: '$neutral05', fs: '16px', fw: '500', lh: '20px', mb: '32px' }}>
                Try adjusting your dropdown filter in the top right.
            </Text>
        </FlexColumn>
    )
}

const renderPageTitle = ({ projectsCount }) => (
    <Flex direction="row" justify="start" align="baseline" css={{ mb: '40px', gap: '8px' }}>
        <Text css={{ fs: '32px', fw: '700', fg: '$neutral04', lh: '40px' }}>Projects</Text>
        <Text css={{ fs: '20px', fw: '500', fg: '$neutral05' }}>({projectsCount})</Text>
    </Flex>
)

const renderSearchBox = ({ onSearchPhraseChanged }) => (
    <StyledSearchInputWrapper>
        <StyledSearchIcon iconSize="24" css={{ color: '$neutral05' }} name="search" />
        <TextInput
            data-cy="input-search-project"
            placeholder="Search for projects..."
            onBlur={onSearchPhraseChanged}
            onChange={onSearchPhraseChanged}
        />
    </StyledSearchInputWrapper>
)

const renderNewProjectButton = ({ onNewProjectClick }) => (
    <Button variant="primary" size="lg" onClick={onNewProjectClick}>
        <Icon iconSize="18" name="newProject" />
        <Text>New Project</Text>
    </Button>
)

const renderProjectsFilter = ({ filterOptions, value, onValueChange }) => {
    return (
        <Flex direction="row" css={{ alignItems: 'center', gap: '8px', marginRight: '16px' }}>
            <Text css={{ color: '$neutral04', fs: '14px', fw: '500' }}>Showing</Text>
            <Select
                data-cy="projects-filter"
                options={filterOptions}
                value={value}
                onValueChange={onValueChange}
                capitalize
                css={{
                    lineHeight: '30px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    height: 40,
                    minWidth: '164px',
                    boxSizing: 'border-box',
                }}
            />
        </Flex>
    )
}

const DownloadTheApp = () => {
    const css = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '$neutral09',
        ai: 'center',
        justifyContent: 'center',
        position: 'fixed',
    }

    return (
        <FlexColumn css={css}>
            <Logo css={{ minWidth: 64, mb: 16 }} />
            <Box css={{ fs: 40, fw: 800, mt: 32, textAlign: 'center', color: '$neutral04', lineHeight: 1.2 }}>
                Welcome to
                <br /> Range
            </Box>
            <Box css={{ fs: 16, fw: 400, w: 320, mt: 20, mb: 32, textAlign: 'center', color: '$neutral05' }}>
                To continue using Range on a mobile device, you’ll need to download the mobile app. Tap below to get
                started.
            </Box>
            <Flex css={{ mb: 8 }}>
                <a href="itms-apps://itunes.apple.com/app/id1664176768">
                    <img src="/iOSappstore.svg" alt="Download on the iOS App Store" width="200px" height="67px" />
                </a>
            </Flex>
            <Flex>
                <a href="https://play.google.com/store/apps/details?id=io.range.app">
                    <img src="/google-play-badge.png" alt="Download on Google Play Store" width="228px" height="auto" />
                </a>
            </Flex>
        </FlexColumn>
    )
}

const renderProjectsView = ({
    searchPhrase,
    projects,
    projectsFilterValue,
    projectMetrics,
    onProjectSelected,
    sortByOptions,
    sortBy,
    onSortByChange: setSortBy,
    canManageProject: isOrganizationAdmin,
    onHideProjectClick,
    onShowProjectClick,
    onDeleteProjectClick,
}) => {
    return projects.length
        ? renderProjectButtons({
              searchPhrase,
              projects,
              projectMetrics,
              onProjectSelected,
              sortByOptions,
              sortBy,
              onSortByChange: setSortBy,
              canManageProject: isOrganizationAdmin,
              onHideProjectClick,
              onShowProjectClick,
              onDeleteProjectClick,
          })
        : renderNoFilteredProjectsView({ projectsFilterValue })
}

/*
 * Show the list of Projects for the User's currently-selected Organization
 */
const AdminViewProjects = () => {
    // Main
    const navigate = useNavigate()
    const { getState, dispatch } = useStore()
    const organizations = useSelector(ReduxSelectors.organizationsAsArray)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const projects = useSelector(ReduxSelectors.userProjectsForSelectedOrganizationAsArray)
    const projectMetrics = useSelector(ReduxSelectors.allProjectMetrics)
    const { runCommand, resourceRemoved } = useCommandHistory()
    const [searchPhrase, setSearchPhrase] = useState(null)
    const user = useSelector(ReduxSelectors.selectedUser)
    const canCreateProject = useSelector(ReduxSelectors.isCreateAllowed('project'))
    const isOrganizationAdmin = useSelector(ReduxSelectors.isUpdateAllowed('organization'))
    const [recentlyViewedProjects, setRecentlyViewedProjects] = useLocalStorage('recentlyViewedProjects', [])
    const [sortBy, setSortBy] = useState('recentlyViewed')
    const [projectsFilterValue, setProjectsFilterValue] = useState('active')
    const [projectToBeDeleted, setProjectToBeDeleted] = useState()

    useEffect(() => {
        // if we're mounting the Projects list, then we also want to unload the current Project
        dispatch(ReduxActions.projectUnloaded())
        resourceRemoved('projectId')

        const archivedProjects = projects.filter(p => p.isArchived)
        const segmentParams = ReduxSelectors.paramsForTrackEvent(getState())
        segmentParams.projectsActiveCount = projects.length - archivedProjects.length
        segmentParams.projectsArchiveCount = archivedProjects.length
        Segment.sendTrack('organization projects viewed', v4(), segmentParams)
    }, [])

    useEffect(() => {
        // If there are invitations to accept, redirect to there instead of showing the project list
        if (user?.invitations?.length) navigate('/acceptInvitations')
    }, [user])

    useEffect(() => {
        updateStatusCounts(dispatch, projects)
    }, [projects])

    // Sort projects by recently viewed
    const sortByRecentlyViewed = projects =>
        projects.sort((a, b) => {
            // The recentlyViewedProjects array is pulled from the local storage.
            let indexA = recentlyViewedProjects.indexOf(a.id)
            let indexB = recentlyViewedProjects.indexOf(b.id)

            // When project is not in the recentlyViewedProjects (wasn't viewed by user)
            // place it at the end
            if (indexA === -1) indexA = projects.length
            if (indexB === -1) indexB = projects.length

            return indexA - indexB
        })

    const sortedAndFilteredProjects = useMemo(() => {
        let filteredProjects = projects

        if (projectsFilterValue === 'active') filteredProjects = projects.filter(project => !project.isArchived)
        else if (projectsFilterValue === 'archived') filteredProjects = projects.filter(project => project.isArchived)

        if (sortBy === 'alphabetical')
            return filteredProjects.sort(
                (a, b) => a.name?.localeCompare(b.name) // sort alphabetically A-Z by project name
            )
        else if (sortBy === 'recentlyViewed') return sortByRecentlyViewed(filteredProjects)
        return filteredProjects
    }, [projects, projectsFilterValue, sortBy])

    const filteredProjectsCount = sortedAndFilteredProjects.length

    const onProjectSelected = project => {
        setRecentlyViewedProjects([project.id, ...recentlyViewedProjects])
        navigate(`../${project.id}`)
    }

    const canAddMoreProjects = () => {
        if (!selectedOrganization.limitsAndCounts) return true
        const { projectCountLimit } = selectedOrganization.limitsAndCounts
        const currentProjectCount = selectedOrganization.projectIds.length || 0
        return currentProjectCount < projectCountLimit
    }

    const onNewProjectClick = () => {
        if (!canAddMoreProjects()) dispatch(ReduxActions.showPlanLimitModal())
        else navigate('../createProject')
    }

    const onSearchPhraseChanged = newSearchPhrase => setSearchPhrase(newSearchPhrase?.trim().toLowerCase())

    const onHideProjectClick = project => runCommand(ProjectChangedCommand.Outbound(project.id, { isArchived: true }))
    const onShowProjectClick = project => runCommand(ProjectChangedCommand.Outbound(project.id, { isArchived: false }))
    const onDeleteProjectClick = project => {
        // show delete confirmation modal
        setProjectToBeDeleted(project)
    }
    const onDeleteConfirmed = () => {
        runCommand(ProjectRemovedCommand.Outbound(projectToBeDeleted.id))
        setProjectToBeDeleted(null)
    }

    const isViewEmpty = projects.length === 0
    const projectFilterOptions = [
        { id: 'active', name: 'Active projects' },
        { id: 'all', name: 'All projects' },
        { id: 'archived', name: 'Archived projects' },
    ]
    const sortByOptions = [
        { id: 'recentlyViewed', name: 'Recently viewed' },
        { id: 'alphabetical', name: 'A-Z' },
    ]

    /* Send a user on an iOS device to the App Store (!window.MSStream is mlagic to avoid some Windows phones) */
    if (/iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
        return <DownloadTheApp />
    }

    if (/Android/.test(navigator.userAgent) && !window.MSStream) {
        return <DownloadTheApp />
    }

    return (
        <Flex css={{ w: '100vw', h: '100vh', bg: '$neutral10' }}>
            <AdminOrganizationsPanel
                relativePath="projects"
                organizations={organizations}
                selectedOrganizationId={selectedOrganization?.id}
            />
            {projectToBeDeleted && (
                <DeleteProjectModal
                    onCancel={() => setProjectToBeDeleted(null)}
                    onSubmit={onDeleteConfirmed}
                    project={projectToBeDeleted}
                />
            )}
            <AdminNavigationPanel selectedPanel={AdminNavigationType.PROJECTS} />
            <FlexColumn css={{ flexGrow: 3, m: '64px 42px 0px 42px' }}>
                {renderPageTitle({ projectsCount: filteredProjectsCount })}
                <FlexRow css={{ mb: '16px' }}>
                    {renderSearchBox({ onSearchPhraseChanged })}
                    {renderProjectsFilter({
                        filterOptions: projectFilterOptions,
                        value: projectsFilterValue,
                        onValueChange: setProjectsFilterValue,
                    })}
                    {canCreateProject && renderNewProjectButton({ onNewProjectClick })}
                </FlexRow>

                {isViewEmpty
                    ? renderEmptyView({ canCreateProject, onNewProjectClick })
                    : renderProjectsView({
                          searchPhrase,
                          projectsFilterValue,
                          projects: sortedAndFilteredProjects,
                          projectMetrics,
                          onProjectSelected,
                          sortByOptions,
                          sortBy,
                          onSortByChange: setSortBy,
                          canManageProject: isOrganizationAdmin,
                          onHideProjectClick,
                          onShowProjectClick,
                          onDeleteProjectClick,
                      })}
            </FlexColumn>
        </Flex>
    )
}

export default AdminViewProjects
