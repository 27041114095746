import { sampleCategoryIds as categoryIds } from './sample-categories.js'

const SampleIds = {
    emulatorUser: '006a3ff0-73f5-4718-85db-77b77e7cd3ec',
    jason: '3d5b6c9e-e23a-4efe-8edc-97caf4ca546d',
    jeff: '3e4637e1-02f4-4fe3-b714-c281fb1ea691',
    rafal: '2d0bb4c2-a017-4624-bea0-4c5856693743',
    sam: '8931bee0-0e8d-46ed-99cc-0b655b068e95',
    tom: 'a3dff7cb-1db3-4fb0-bcab-4fe150f182ed',
    greg: 'a32def8b-73c1-4462-9458-5f1e82f3750c',

    range: 'c39f1e8b-cabb-44c9-822e-e0d7ee92a90d',
    tomRa: '84fc607b-1d23-449d-bb28-036941502492',

    vanderbiltProject: '474503d1-1adc-4268-88fe-2a797148f16d',
    vanderbiltPage1: 'f2ddb9ef-47b8-4ace-b0ee-08b6bdd80546',
    vanderbiltPage2: 'e5db7eb2-bd9f-44ba-9c90-e6e6e528b930',
    vanderbiltCollaboration1: '13083d62-03a1-43d7-8eb0-ca5bb8b357f3',
    vanderbiltCollaboration1Update1: '4f05e839-13ab-4c81-81c2-daffd1927819',
    vanderbiltCollaboration2: '980ed7a0-4f88-4dbd-be30-883e20929fe9',
    vanderbiltCollaboration3: 'f4d21fb1-cdae-48c2-b71d-1cd38e1ef152',
    vanderbiltComment1: 'd7dc75fd-331d-4baa-b49e-403a7183280e',
    vanderbiltComment2: 'b250d754-4af1-4fe4-a3cd-3ffa96fe1e0a',
    vanderbiltUpload1Comment1: '6646df1c-b668-498e-a86a-50b2b9b955f4',
    vanderbiltFeature1: '0e16160f-0a4e-4472-9faf-512f6bebb0df',
    vanderbiltFeature2: '00b17408-14cb-46c6-9d1e-ae108ebacf02',
    vanderbiltFeature3: '550751a4-fa5b-414f-8b46-8cbfb37c32a1',
    vanderbiltGeometry1: '0521fccf-3799-4786-aab1-e5e5108c0807',
    vanderbiltGeometry2: '81dc1f6c-7fe5-49c1-8213-9844f92ccfe3',
    vanderbiltGeometry3: 'b98964a5-106c-4430-b101-18c9ce407ed6',
    vanderbiltUpload1: '78e11ecf-b87f-4542-833d-e2aebbd8a192',
    vanderbiltUpload2: '2aad571f-1286-4e62-91cd-bde67dc2b2b3',
    vanderbiltUpload3: '2aad571f-1286-4e62-91cd-bde67dc2b2dd',
    vanderbiltUpload4: '95c013be-bfc8-4122-90c0-c430a13fb044',
    vanderbiltFeedItem1: '6ea277b6-1828-4396-b0ec-7a1866a45702',

    vanderbiltInProgressStatus: '5d3954a0-8d82-4e5a-a3c1-a11dacb44877',
    vanderbiltBlockedStatus: '6aeb1774-db4e-470b-882d-a0ad3912f01c',
    vanderbiltNeedsReviewStatus: '7ac8bfd0-0a11-4d1e-a42e-5ad3ef5b9e38',
    vanderbiltCompleteStatus: '9a92a328-d2d5-495c-9315-de00eae44aa0',
    vanderbiltMapboxCanvasSource: 'f2ddb9ef-47b8-4ace-b0ee-08b6bdd80549',
    vanderbiltDroneDeployCanvasSource: 'f2ddb9ef-47b8-4ace-b0ee-08b6bdd80550',
    vanderbiltPdfCanvasSource: 'f2ddb9ef-47b8-4ace-b0ee-08b6bdd80560',

    ridgedaleAveProject: 'ab901606-3a6a-4f35-a095-478caa3841ef',
    ridgedaleAveCanvas1: '2570db01-741b-4800-9c68-c6e6c3465309',
    ridgedaleAvePdfCanvasSource: '2b7f3c9b-31d9-4ac0-92d8-1c252c7a2485',
    ridgedaleInProgressStatus: 'a85ca72c-77ca-4a6d-8fd2-5871d0f052ad',
    ridgedaleBlockedStatus: 'c0fcfb6c-c1a0-437e-82de-c4475a22e3e5',
    ridgedaleNeedsReviewStatus: '3c1c50b2-2d3e-4025-9a43-2dfd98f44399',
    ridgedaleCompleteStatus: 'b401dead-d383-456d-814a-f0b13ae65ad1',

    thePreserveProject: '7fd7c334-9ba2-4cba-9b02-0821a9028d0c',
    thePreservePdfCanvasPage0: 'e0d674a2-6ea6-43df-ae63-e87017333f53',
    thePreservePdfCanvasPage1: 'af6872c7-aa39-487a-9ee5-ff532300ab75',
    thePreservePdfCanvasPage2: 'c55c28a4-de79-4b25-bd75-3b9fbb03793e',
    thePreservePdfCanvasPage3: '87ee3d6d-8d93-4623-ad4d-a22abe704741',
    thePreservePdfCanvasPage4: '67f113bb-e7f9-4aea-81f6-370e99a9052c',
    thePreserveDroneDeployCanvas: 'd50a36e5-98e9-4856-8fa4-55456773f5a4',
    thePreserveDroneDeployCanvasSource: 'bc7a79a0-ae8f-42f1-851f-a5c6e1fd7348',
    thePreservePdfPage0CanvasSource: '2cd666c1-4a03-4b73-bad1-8b023bda96f1',
    thePreservePdfPage1CanvasSource: '08b7464e-b4a8-4a03-901f-8409c09ab3d9',
    thePreservePdfPage2CanvasSource: 'addb64db-5623-4015-bce4-90ff0b4a9b0a',
    thePreservePdfPage3CanvasSource: '619e5d09-ce82-464f-b861-719187685878',
    thePreservePdfPage4CanvasSource: '5cca6dca-2a2d-4ffd-9d11-3bb9dd217b4a',
    thePreserveInProgressStatus: '469750e1-5f63-40b8-b312-d5a6a1da36b1',
    thePreserveBlockedStatus: '6abb0384-4f99-40bd-afcd-492b019ea294',
    thePreserveNeedsReviewStatus: 'ac3a2a72-e62f-45cf-8913-89c61c1f3b92',
    thePreserveCompleteStatus: '17dca097-304f-4279-a812-fe0738270ee6',

    tagKeyboard: '43fa32f9-0491-4489-ba47-70a7d69922f0',
    tagTrackpad: 'ab1751b8-8f41-4181-8a15-5c1b1d39625c',

    categoryIds,
}

export default SampleIds
