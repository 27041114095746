import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import { uploadCanvasSource } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { reportPromisesProgress, updateProgressElement } from './helpers/report-progress'
import { createProject } from './https-calls.js'

// prettier-ignore
const ProjectMetadata = tagged('ProjectMetadata', {
    address       : 'String',
    canvasOrder   : StringTypes.Ids,
    canvasSources : '[CanvasSource]',
    canvases      : '[Canvas]',
    center        : '[Number]',
    description   : 'String?',
    name          : 'String',
    organizationId: StringTypes.Id,
    projectId     : StringTypes.Id,
    projectType   : 'String',
    tagSource     : StringTypes.OptionalId,
    statusSource  : StringTypes.OptionalId,
    categorySource: StringTypes.OptionalId
})
// ---------------------------------------------------------------------------------------------------------------------
// ProjectAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const ProjectAddedCommand = taggedSum('ProjectAddedCommand', {
    Inbound: {},
    Outbound: { project: 'ProjectMetadata', files: 'Object' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

// we'll never receive one of these; if you're on the Projects page, you'll be listening to /projects instead
const runInboundCommand = (resources, command) => () => {}

/*
 * The user created a new project (using the NewProjectWizard)
 * Return true on success so the UI can be updated properly
 */
const runOutboundCommand = async (resources, command) => {
    const uploadCanvasSourceFile = cs => uploadCanvasSource(project.projectId, cs.id, cs.pdfFile)

    const { project, files } = command
    const { dispatch, getState } = resources

    // By uploading CanvasSource AFTER the Project has been created, and its participants set, we can keep the
    // Storage security rules tighter and simpler: you can read/write to Storage only if you're a Project participant
    try {
        await createProject(project)

        const getProgressText = (proceededCount, promisesCount) =>
            `Uploading ${++proceededCount}/${promisesCount} PDF sheets...`
        await Promise.all(reportPromisesProgress(files.map(uploadCanvasSourceFile), getProgressText))

        updateProgressElement('Your project has been successfully created')
        dispatch(ReduxActions.projectAdded(project)) // add the Project immediately

        const params = ReduxSelectors.paramsForProjectTrackEvent(getState(), project)
        Segment.sendTrack('project created', project.projectId, params)

        return true
    } catch (e) {
        const errorAlert = {
            title: 'Upload Failed',
            description: 'You can try again',
            cancelButton: {
                label: 'Ok',
            },
        }
        dispatch(ReduxActions.globalModalDataSet(errorAlert))
        console.error('Something went wrong when uploading the canvas', e)
        return false
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectAddedCommand,
        CommandPlayer({
            CommandType: ProjectAddedCommand,
            Type: ProjectMetadata,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'organizationId',
        })
    )
}

export { ProjectMetadata, ProjectAddedCommand, addCommand }
