import PropTypes from 'prop-types'
import React from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import {
    Button,
    DropdownMenuWithTooltip,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Text,
} from '../components-reusable/index.js'
import { CollaborationChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors, useSelectorWith } from '../redux/index.js'
import { CategorySelector } from './CategorySelector.js'
import { DeleteCollaborationModal, ProceedModal } from './Modal.js'

const StyledTopBar = styled(FlexRow, {
    color: '$neutral01',
    padding: '16px 24px 16px 16px',
    borderRadius: '6px 6px 0 0',
    borderBottom: '1px solid $neutral07',
    background: '$neutral10',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 73,
})

const showDeleteButton = showDeleteWarning => (
    <DropdownMenuWithTooltip.MenuItem>
        <Button variant="dropdown-menuitem-destructive" onClick={showDeleteWarning} css={{ bg: 'none' }}>
            <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
            <Text>Delete</Text>
        </Button>
    </DropdownMenuWithTooltip.MenuItem>
)

const MoreActions = ({
    allowDelete,
    allowDuplicate,
    allowArchive,
    showConvertToTaskMenuItem,
    archiveText,
    canvases,
    currentCanvasId,
    showDeleteWarning,
    showConvertWarning,
    initialOpen,
    onDuplicate,
    onShare,
    onArchive,
    onMoveToCanvas,
}) => (
    <DropdownMenuWithTooltip.Root initialOpen={initialOpen}>
        <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
            <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger">
                <Icon iconSize="28px" name="threeDot" />
            </Button>
        </DropdownMenuWithTooltip.Trigger>
        <DropdownMenuWithTooltip.Portal>
            <DropdownMenuWithTooltip.Content side="bottom" align="end" sideOffset={6}>
                {showConvertToTaskMenuItem && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem" onClick={showConvertWarning}>
                            <Icon iconSize="16px" name="convertToTask" css={{ mr: 8 }} />
                            <Text>Convert to Task</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}
                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem" onClick={onShare}>
                        <Icon iconSize="16px" name="share16x17" css={{ mr: 8 }} />
                        <Text>Share</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
                {allowDuplicate && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem" onClick={onDuplicate}>
                            <Icon iconSize="16px" name="duplicate" css={{ mr: 8 }} />
                            <Text>Duplicate</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}
                {allowArchive && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem" onClick={onArchive}>
                            <Icon iconSize="16px" name="archive" css={{ mr: 8 }} />
                            <Text>{archiveText}</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}
                <DropdownMenuWithTooltip.Sub>
                    <DropdownMenuWithTooltip.SubTrigger>
                        <Button variant="dropdown-menuitem">
                            <Icon iconSize="16px" name="levels" css={{ mr: 8 }} />
                            <Text>Move to Canvas</Text>
                        </Button>
                    </DropdownMenuWithTooltip.SubTrigger>
                    <DropdownMenuWithTooltip.Portal>
                        <DropdownMenuWithTooltip.SubContent sideOffset={5}>
                            {canvases
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .filter(canvas => canvas.id !== currentCanvasId)
                                .map((canvas, index) => {
                                    return (
                                        <DropdownMenuWithTooltip.MenuItem key={`moveToCanvas-${index}`}>
                                            <Button variant="dropdown-menuitem" onClick={() => onMoveToCanvas(canvas)}>
                                                <Text>{canvas.name}</Text>
                                            </Button>
                                        </DropdownMenuWithTooltip.MenuItem>
                                    )
                                })}
                        </DropdownMenuWithTooltip.SubContent>
                    </DropdownMenuWithTooltip.Portal>
                </DropdownMenuWithTooltip.Sub>
                {allowDelete && showDeleteButton(showDeleteWarning)}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Portal>
    </DropdownMenuWithTooltip.Root>
)

const CollaborationWindowTopBar = ({
    allowDelete = false,
    allowDuplicate = false,
    allowArchive = false,
    archiveText,
    canvases,
    collaborationShape,
    currentCanvasId,
    initialOpen,
    uploadsLimitReached,
    onClose,
    onAttach,
    onDelete,
    onDuplicate,
    onArchive,
    onShare,
    onConvertToTask,
    showGoToCanvas = false,
    onGoToCanvas,
    onMoveToCanvas,
    onTakeCanvasSnippet,
}) => {
    const { get: isDeleteWarningVisible, set: showDeleteWarning, reset: hideDeleteWarning } = useBooleanState()
    const { get: isConvertWarningVisible, set: showConvertWarning, reset: hideConvertWarning } = useBooleanState()

    const _onDelete = () => {
        hideDeleteWarning()
        onDelete()
    }

    const _onConvert = () => {
        hideConvertWarning()
        onConvertToTask()
    }

    const setSelectedCategory = category =>
        runCommand(CollaborationChangedCommand.Outbound(collaboration.id, { categoryId: category.id }, selectedUserId))

    const planLimitText = 'Feature unavailable. Plan limits reached'

    const collaboration = useSelector(ReduxSelectors.selectedCollaboration)
    const selectedUserId = useSelector(ReduxSelectors.selectedUserId)
    const { runCommand } = useCommandHistory()

    const selectedCategory = useSelectorWith(ReduxSelectors.categoryForCollaboration, collaboration)

    return (
        <StyledTopBar>
            {isDeleteWarningVisible() &&
                createPortal(
                    <DeleteCollaborationModal onDelete={_onDelete} onCancel={hideDeleteWarning} />,
                    document.body
                )}
            {isConvertWarningVisible() &&
                createPortal(
                    <ProceedModal
                        onAction={_onConvert}
                        onCancel={hideConvertWarning}
                        buttonLabel="Convert to Task Pin"
                        primaryLabel="Are you sure you want to convert this Photo Pin to a Task Pin?"
                        secondaryLabel="Doing this means you can add a description, tags and assign a status, due date and assignee. The style of the map pin will also change."
                    />,
                    document.body
                )}
            <FlexRow css={{ gap: 4, width: '100%', alignItems: 'center', pl: 8 }}>
                <CategorySelector setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
                <IconButtonWithTooltip
                    iconName="scissors"
                    size="20px"
                    tooltipText={uploadsLimitReached ? planLimitText : 'Add snippet'}
                    disabled={uploadsLimitReached}
                    side="bottom"
                    onClick={onTakeCanvasSnippet}
                    variant="neutralHover32"
                    css={{ color: '$pink03', '&:hover': { color: '$pink04', background: '$pink01' } }}
                />
                {showGoToCanvas && (
                    <IconButtonWithTooltip
                        iconName="levels"
                        size="20px"
                        tooltipText="View on canvas"
                        side="bottom"
                        onClick={onGoToCanvas}
                        variant="neutralHover32"
                    />
                )}
                <IconButtonWithTooltip
                    iconName="attachFiles"
                    size="20px"
                    tooltipText={uploadsLimitReached ? planLimitText : 'Attach Media'}
                    disabled={uploadsLimitReached}
                    side="bottom"
                    onClick={onAttach}
                    variant="neutralHover32"
                />
                <MoreActions
                    allowDelete={allowDelete}
                    allowDuplicate={allowDuplicate}
                    allowArchive={allowArchive}
                    showConvertToTaskMenuItem={!!onConvertToTask} // undefined implies that it's already a task
                    archiveText={archiveText}
                    canvases={canvases}
                    currentCanvasId={currentCanvasId}
                    showDeleteWarning={showDeleteWarning}
                    showConvertWarning={showConvertWarning}
                    initialOpen={initialOpen}
                    onShare={onShare}
                    onArchive={onArchive}
                    onMoveToCanvas={onMoveToCanvas}
                    onDuplicate={onDuplicate}
                />
                <IconButtonWithTooltip
                    dataCy="collaboration-window-close"
                    iconName="close24x24"
                    size="20px"
                    tooltipText="Close"
                    side="bottom"
                    onClick={onClose}
                    variant="neutralHover32"
                />
            </FlexRow>
        </StyledTopBar>
    )
}

CollaborationWindowTopBar.displayName = 'CollaborationWindowTopBar'
CollaborationWindowTopBar.propTypes = {
    archiveText: PropTypes.string.isRequired,
    currentCanvasId: PropTypes.string.isRequired,
    initialOpen: PropTypes.bool,
    onArchive: PropTypes.func.isRequired,
    onAttach: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConvertToTask: PropTypes.func, // required IF the Collaboration is not yet a task
    onDelete: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onGoToCanvas: PropTypes.func,
    showGoToCanvas: PropTypes.bool,
}

export default CollaborationWindowTopBar
