/* ---------------------------------------------------------------------------------------------------------------------
 * MockQuery
 * ---------------------------------------------------------------------------------------------------------------------
 * https://firebase.google.com/docs/reference/js/firestore_.query
 *
 *      converter    Converter        If provided, the FirestoreDataConverter associated with this instance.
 *      firestore    Firestore        The Firestore instance for the Firestore database (useful for performing transactions, etc.).
 *      type        query|collection  The type of this Firestore reference.
 * ------------------------------------------------------------------------------------------------------------------ */

// type: 'collection|query'
// Firestore doesn't seem to expose any interface to retrieve the constraints from an existing Query
import { tagged } from '@range.io/functional'

const MockQuery = tagged('MockQuery', {
    reference: 'MockCollectionReference',
    wheres: 'Object?', // Where|[Where]
})

export default MockQuery
