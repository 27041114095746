/* ---------------------------------------------------------------------------------------------------------------------
 * MockTimestamp
 * ---------------------------------------------------------------------------------------------------------------------
 * https://firebase.google.com/docs/reference/js/firestore_.timestamp
 *
 * Fields
 *
 *  >>> seconds      The number of seconds of UTC time since Unix epoch 1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to 9999-12-31T23:59:59Z inclusive.
 *  >>> nanoseconds: The non-negative fractions of a second at nanosecond resolution. Negative second values with fractions must still have non-negative nanoseconds values that count forward in time. Must be from 0 to 999,999,999 inclusive.
 *
 * Static
 *
 *  >>> fromDate(date)            Timestamp Creates a new timestamp from the given date.
 *  >>> fromMillis(milliseconds)  Timestamp Creates a new timestamp from the given number of milliseconds.
 *      now()                     Timestamp Creates a new timestamp with the current date, with millisecond precision.
 *
 * Method
 *
 *      isEqual(other) Boolean Returns true if this Timestamp is equal to the provided one.
 *  >>> toDate()       Date    Converts a Timestamp to a JavaScript Date object. This conversion causes a loss of precision since Date objects only support millisecond precision.
 *      toJSON()       String  Returns a JSON-serializable representation of this Timestamp.
 *  >>> toMillis()     Number  Returns a Timestamp to a numeric timestamp (in milliseconds since epoch). This operation causes a loss of precision.
 *      toString()     String  Returns a textual representation of this Timestamp.
 *      valueOf()
 *
 * Note: this is the CLIENT (SDK v9) version of Timestamp. We never need to mock the firebase-admin version, though it
 * seems to have an identical API (https://cloud.google.com/nodejs/docs/reference/firestore/latest/firestore/timestamp)
 * ------------------------------------------------------------------------------------------------------------------ */
import { tagged } from '@range.io/functional'

const MockTimestamp = tagged('MockTimestamp', {
    seconds: 'Number',
    nanos: 'Number',
})

// MockTimestamp -> Number
MockTimestamp.prototype.toMillis = function () {
    return this.seconds * 1_000 + this.nanos / 1_000_000
}

// MockTimestamp -> Date
MockTimestamp.prototype.toDate = function () {
    return new Date(this.toMillis())
}

// Date -> MockTimestamp
MockTimestamp.fromDate = function () {
    return MockTimestamp.fromMillis(Date.now())
}

// () -> MockTimestamp
MockTimestamp.now = function () {
    return MockTimestamp.fromMillis(Date.now())
}

// Number -> MockTimestamp
MockTimestamp.fromMillis = ms => MockTimestamp(Math.floor(ms / 1000), (ms % 1000) * 1000000)

export default MockTimestamp
