/*
 * @sig categories :: State -> LookupTable<Category>
 */
const categories = state => state.categories

const defaultCategory = state => state.categories.find(c => c.isDefault)

// Collaboration -> Category
// when deleting a category, the collaboration's category may be gone, in which canse use the default category
const categoryForCollaboration = (state, collaboration) =>
    state.categories[collaboration?.categoryId] || defaultCategory(state)

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const CategorySelectors = {
    categories,
    categoryForCollaboration,
    defaultCategory,
}

export default CategorySelectors
